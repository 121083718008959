/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    getFinalPricePerCase,
    getFinalPricePerItem,
    getPricePerItem,
    getTotalItemQuantity
} from 'Util/BulkProduct';
import { roundFloat } from 'Util/Common/Number';
import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Data/Product/getItemIdentifyingData */
export const getItemIdentifyingData = (product) => {
    const { sku, name } = product;

    return {
        item_id: sku,
        item_name: name
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getCurrencyData */
export const getCurrencyData = async () => {
    await waitForCallback(() => getStore().getState().ConfigReducer?.currencyData?.current_currency_code);

    const { currencyData: { current_currency_code } = {} } = getStore().getState().ConfigReducer;

    return {
        currency: current_currency_code
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getItemQuantityData */
export const getItemQuantityData = (product) => ({
    quantity: getTotalItemQuantity(product, 1)
});

/** @namespace Scandiweb/Gtm/Data/Product/getValueData */
export const getValueData = (product) => ({
    value: roundFloat(getFinalPricePerCase(product, 1))
});

/** @namespace Scandiweb/Gtm/Data/Product/getItemPriceData */
export const getItemPriceData = (product) => {
    const finalPricePerItem = roundFloat(getFinalPricePerItem(product, 1));
    const pricePerItem = roundFloat(getPricePerItem(product, 1));
    const discount = roundFloat(pricePerItem - finalPricePerItem);

    return {
        price: finalPricePerItem,
        discount
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getItemBulkProductData */
export const getItemBulkProductData = (product) => {
    const { bulk_product_case_info = [], bulk_product_price_tiers = [] } = product;

    return {
        bulk_product_case_info,
        bulk_product_price_tiers: bulk_product_price_tiers.reverse()
    };
};

/** @namespace Scandiweb/Gtm/Data/Product/getViewItemData */
export const getViewItemData = async (product, categories) => ({
    ecommerce: {
        ...await getCurrencyData(),
        ...getValueData(product),
        items: [{
            ...getItemIdentifyingData(product),
            ...getItemQuantityData(product),
            ...getItemPriceData(product),
            ...categories,
            ...getItemBulkProductData(product)
        }]
    }
});
