import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import BurgerMenuCloseButtonComponent from 'Component/BurgerMenuCloseButton/BurgerMenuCloseButton.component';
import { POSITION_RIGHT } from 'Component/BurgerMenuCloseButton/BurgerMenuCloseButton.config';

/** @namespace EroswholesaleScandipwa/Component/BurgerMenuCloseButton/Container/mapStateToProps */
export const mapStateToProps = (_state) => ({});
/** @namespace EroswholesaleScandipwa/Component/BurgerMenuCloseButton/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace EroswholesaleScandipwa/Component/BurgerMenuCloseButton/Container */
export class BurgerMenuCloseButtonContainer extends PureComponent {
    static defaultProps = {
        position: POSITION_RIGHT
    };

    static propTypes = {
        onClick: PropTypes.func.isRequired,
        position: PropTypes.string
    };

    containerProps() {
        const { onClick, position } = this.props;

        return {
            onClick,
            position
        };
    }

    render() {
        return (
            <BurgerMenuCloseButtonComponent
                { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BurgerMenuCloseButtonContainer);
