import _ from 'lodash';

/** @namespace EroswholesaleScandipwa/Util/Html/Index/removeCssClass */
export const removeCssClass = (element, classname) => element.classList.remove(classname);

/** @namespace EroswholesaleScandipwa/Util/Html/Index/addCssClass */
export const addCssClass = (element, classname) => element.classList.add(classname);

/** @namespace EroswholesaleScandipwa/Util/Html/Index/getCssClassnamesThatStartWith */
export const getCssClassnamesThatStartWith = (element, startsWith) => _.filter(element.classList, (classname) => classname.startsWith(startsWith));

/** @namespace EroswholesaleScandipwa/Util/Html/Index/removeCssClassnamesThatStartWith */
export const removeCssClassnamesThatStartWith = (element, startsWith) => getCssClassnamesThatStartWith(element, startsWith).forEach((classname) => removeCssClass(element, classname));
