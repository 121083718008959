import PropTypes from 'prop-types';

import { FieldNumberContainer as SourceFieldNumberContainer } from 'SourceComponent/FieldNumber/FieldNumber.container';

/** @namespace EroswholesaleScandipwa/Component/FieldNumber/Container */
export class FieldNumberContainer extends SourceFieldNumberContainer {
    static defaultProps = {
        ...SourceFieldNumberContainer.defaultProps,
        handlePlusIconClick: null,
        handleMinusIconClick: null
    };

    static propTypes = {
        ...SourceFieldNumberContainer.propTypes,
        handlePlusIconClick: PropTypes.func,
        handleMinusIconClick: PropTypes.func
    };

    onFocusHandler(e) {
        e.currentTarget.select();
    }

    onBlurHandler(e) {
        this.handleValueChange.bind(this)(e.currentTarget.value);
    }

    onChangeHandler(e) {
        this.handleValueChange.bind(this)(e.currentTarget.value);
    }

    onWheelHandler(e) {
        e.currentTarget.blur();
    }

    getEventsProp() {
        const {
            events,
            events: {
                onFocus, onBlur, onChange
            }
        } = super.containerProps();

        const newEvents = {
            ...events,
            onWheel: this.onWheelHandler.bind(this),
            onFocus: this.onFocusHandler.bind(this),
            onBlur: this.onBlurHandler.bind(this),
            onChange: this.onChangeHandler.bind(this)
        };

        if (typeof onFocus === 'function') {
            newEvents.onFocus = (e) => {
                onFocus(e);
                this.onFocusHandler.bind(this)(e);
            };
        }

        if (typeof onBlur === 'function') {
            newEvents.onBlur = (e) => {
                this.onBlurHandler.bind(this)(e);
                onBlur(e);
            };
        }

        if (typeof onChange === 'function') {
            newEvents.onChange = (e) => {
                this.onChangeHandler.bind(this)(e);
                onChange(e);
            };
        }

        return newEvents;
    }

    containerProps() {
        const {
            isDisabled,
            handlePlusIconClick,
            handleMinusIconClick
        } = this.props;

        return {
            ...super.containerProps(),
            events: this.getEventsProp(),
            isDisabled,
            usesFloatingLabel: false,
            handlePlusIconClick,
            handleMinusIconClick
        };
    }
}
