import _ from 'lodash';

import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace EroswholesaleScandipwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getResetPasswordMutation(options) {
        const { customerId } = options;
        return super.getResetPasswordMutation(options)
            .addArgument('customer_id', 'String', _.isUndefined(customerId) ? null : customerId);
    }

    _getAddressFields() {
        return [
            ...super._getAddressFields(),
            'company'
        ];
    }

    getAuthenticateWithFirebaseAuthMutation(input) {
        return new Field('authenticateWithFirebaseAuth')
            .addArgument('input', 'AuthenticateWithFirebaseAuthInput!', input)
            .addFieldList([
                'token',
                'error',
                'message'
            ]);
    }
}

export default new MyAccountQuery();
