/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundFloat } from 'Util/Common/Number';
import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Data/Cart/getCartCurrencyCode */
export const getCartCurrencyCode = async () => {
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.quote_currency_code);
    const { cartTotals: { quote_currency_code } } = getStore().getState().CartReducer;

    return quote_currency_code;
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartValueData */
export const getCartValueData = async () => {
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.subtotal);
    const { cartTotals: { subtotal } } = getStore().getState().CartReducer;

    return subtotal;
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartCouponCode */
export const getCartCouponCode = async () => {
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.coupon_code);
    const { cartTotals: { coupon_code } } = getStore().getState().CartReducer;

    return coupon_code;
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemQty */
export const getCartItemQty = (item) => {
    const { qty, bulk_product_case_info: { total_qty } } = item;

    return total_qty || qty;
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemCaseQty */
export const getCartItemCaseQty = (item) => {
    const { qty, bulk_product_case_info: { case_qty } = {} } = item;

    return case_qty || qty;
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemDiscount */
export const getCartItemDiscount = (item) => {
    const { bulk_product_case_info: { price_per_item = 0, final_price_per_item = 0 } = {} } = item;

    return roundFloat(price_per_item - final_price_per_item);
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemPrice */
export const getCartItemPrice = (item) => {
    const { bulk_product_case_info: { final_price_per_item = 0 } = {} } = item;

    return roundFloat(final_price_per_item);
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemTotalValue */
export const getCartItemTotalValue = (item) => {
    const { bulk_product_case_info: { total_qty = 1, final_price_per_item = 0 } = {} } = item;

    return roundFloat(total_qty * final_price_per_item);
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemData */
export const getCartItemData = async (item, categories = {}) => {
    const {
        sku,
        product: { name },
        bulk_product_case_info = {}
    } = item;

    return {
        item_id: sku,
        item_name: name,
        price: getCartItemPrice(item),
        discount: getCartItemDiscount(item),
        quantity: getCartItemQty(item),
        bulk_product_case_info,
        ...categories
    };
};

/** @namespace Scandiweb/Gtm/Data/Cart/getCartItemsData */
export const getCartItemsData = async () => {
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);
    const { cartTotals: { items = [] } } = getStore().getState().CartReducer;

    if (items.length === 0) {
        return {};
    }

    return Promise.all((items.map(getCartItemData)));
};

/** @namespace Scandiweb/Gtm/Data/Cart/getViewCartData */
export const getViewCartData = async () => ({
    ecommerce: {
        currency: await getCartCurrencyCode(),
        value: await getCartValueData(),
        items: await getCartItemsData()
    }
});

/** @namespace Scandiweb/Gtm/Data/Cart/getAddToCartData */
export const getAddToCartData = async (item, categories = {}) => ({
    ecommerce: {
        currency: await getCartCurrencyCode(),
        value: getCartItemTotalValue(item),
        items: [{ ...await getCartItemData(item, categories) }]
    }
});

/** @namespace Scandiweb/Gtm/Data/Cart/getRemoveFromCartData */
export const getRemoveFromCartData = async (item) => ({
    ecommerce: {
        currency: await getCartCurrencyCode(),
        value: getCartItemTotalValue(item),
        items: [{ ...await getCartItemData(item) }]
    }
});
