import PropTypes from 'prop-types';

import { DiscountType, OriginalPriceType, PriceItemType } from 'SourceType/Price.type';

export * from 'SourceType/Price.type';

export const ProductPriceType = PropTypes.shape({
    price: PropTypes.shape({
        finalPrice: PriceItemType,
        finalPriceExclTax: PriceItemType,
        originalPrice: PriceItemType,
        originalPriceExclTax: PriceItemType,
        discount: DiscountType
    }),
    originalPrice: OriginalPriceType,
    configuration: PropTypes.shape({
        containsOptions: PropTypes.bool,
        containsOptionsWithPrice: PropTypes.bool,
        containsRequiredOptions: PropTypes.bool,
        containsRequiredOptionsWithPrice: PropTypes.bool
    })
});
