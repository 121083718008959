/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundFloat } from 'Util/Common/Number';
import getStore from 'Util/Store';

import { waitForCallback } from '../util/wait';
import {
    getCartCouponCode,
    getCartCurrencyCode,
    getCartItemsData,
    getCartValueData
} from './cart';

/** @namespace Scandiweb/Gtm/Data/Checkout/getShippingRatesData */
export const getShippingRatesData = async (shippingMethods) => {
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.id);
    const { cartTotals: { subtotal } } = getStore().getState().CartReducer;

    return shippingMethods.map((shippingMethod) => {
        const { amount } = shippingMethod;

        // eslint-disable-next-line no-magic-numbers
        const percent_of_subtotal = amount > 0 ? roundFloat((amount / subtotal) * 100) : 0;

        return {
            ...shippingMethod,
            percent_of_subtotal
        };
    });
};

/** @namespace Scandiweb/Gtm/Data/Checkout/getPaymentMethodTitle */
export const getPaymentMethodTitle = async (paymentMethod) => {
    if (paymentMethod === null) {
        return '';
    }

    const { title } = paymentMethod;

    return title;
};

/** @namespace Scandiweb/Gtm/Data/Checkout/getBeginCheckoutEventData */
export const getBeginCheckoutEventData = async () => ({
    ecommerce: {
        currency: await getCartCurrencyCode(),
        value: await getCartValueData(),
        coupon: await getCartCouponCode(),
        items: await getCartItemsData()
    }
});

/** @namespace Scandiweb/Gtm/Data/Checkout/getAddShippingInfoEventData */
export const getAddShippingInfoEventData = async (shippingMethods, selectedShippingMethod) => ({
    ecommerce: {
        currency: await getCartCurrencyCode(),
        value: await getCartValueData(),
        coupon: await getCartCouponCode(),
        shipping_tier: selectedShippingMethod,
        shipping_rates: await getShippingRatesData(shippingMethods),
        items: await getCartItemsData()
    }
});

/** @namespace Scandiweb/Gtm/Data/Checkout/getAddPaymentInfoEventData */
export const getAddPaymentInfoEventData = async (paymentMethods, selectedPaymentMethod) => ({
    ecommerce: {
        currency: await getCartCurrencyCode(),
        value: await getCartValueData(),
        coupon: await getCartCouponCode(),
        payment_type: await getPaymentMethodTitle(selectedPaymentMethod),
        payment_methods: paymentMethods,
        items: await getCartItemsData()
    }
});

/** @namespace Scandiweb/Gtm/Data/Checkout/getViewedShippingMethodsEventData */
export const getViewedShippingMethodsEventData = async (shippingMethods) => ({
    ecommerce: {
        shipping_rates: shippingMethods,
        items: await getCartItemsData()
    }
});
