import { toggleScroll } from 'SourceUtil/Browser/Browser';

export * from 'SourceUtil/Browser/Browser';

/** @namespace EroswholesaleScandipwa/Util/Browser/freezeScroll */
export const freezeScroll = () => {
    if (window.isScrollFrozen === true) {
        return;
    }

    window.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
    toggleScroll(false);
    document.body.style.marginTop = `${-window.YoffsetWhenScrollDisabled}px`;
    window.isScrollFrozen = true;
};

/** @namespace EroswholesaleScandipwa/Util/Browser/unfreezeScroll */
export const unfreezeScroll = () => {
    if (window.isScrollFrozen === false) {
        return;
    }

    toggleScroll(true);
    document.body.style.marginTop = 0;
    window.scrollTo(0, window.YoffsetWhenScrollDisabled);
    window.isScrollFrozen = false;
};
