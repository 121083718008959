import MyAccountQuery from 'Query/MyAccount.query';
import {
    CUSTOMER,
    MyAccountDispatcher as SourceMyAccountDispatcher,
    ONE_MONTH_IN_SECONDS
} from 'SourceStore/MyAccount/MyAccount.dispatcher';
import {
    updateCustomerDetails, updateCustomerSignInStatus, updateIsLoading, updateIsLocked
} from 'Store/MyAccount/MyAccount.action';
import { showNotification } from 'Store/Notification/Notification.action';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { getAuthorizationToken, GRAPHQL_AUTH, setAuthorizationToken } from 'Util/Auth';
import BrowserDatabase from 'Util/BrowserDatabase';
import { getGuestQuoteId, setGuestQuoteId } from 'Util/Cart';
import { prepareQuery } from 'Util/Query';
import { executePost, getErrorMessage } from 'Util/Request';

export const CartDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
    );

export const WishlistDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Wishlist/Wishlist.dispatcher'
    );

export const BurgerMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/BurgerMenu/BurgerMenu.dispatcher'
    );

export const ProductCompareDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ProductCompare/ProductCompare.dispatcher'
    );

export * from 'SourceStore/MyAccount/MyAccount.dispatcher';

/** @namespace EroswholesaleScandipwa/Store/MyAccount/Dispatcher */
export class MyAccountDispatcher extends SourceMyAccountDispatcher {
    /**
     * Sign in with Firebase authentication.
     * @param {string} token - The Firebase authentication token.
     * @param {Object} response - The response object.
     * @param {Function} dispatch - The dispatch function.
     * @returns {Promise<Object>} The result and response.
     */
    async signInWithFirebaseAuth(token, response, dispatch) {
        setAuthorizationToken(token);

        const cartDispatcher = (await CartDispatcher).default;
        const guestCartToken = getGuestQuoteId();

        const burgerMenuDispatcher = (await BurgerMenuDispatcher).default;
        await this.requestCustomerData(dispatch);
        await burgerMenuDispatcher.closeAllMenus(dispatch);
        dispatch(hideActiveOverlay());
        dispatch(showNotification('success', __('You have successfully logged in!')));

        const customerCartToken = await cartDispatcher.createGuestEmptyCart(dispatch);

        if (guestCartToken && guestCartToken !== customerCartToken) {
            await cartDispatcher.mergeCarts(guestCartToken, customerCartToken, dispatch);
        }

        setGuestQuoteId(customerCartToken);
        cartDispatcher.updateInitialCartData(dispatch, true);

        dispatch(updateCustomerSignInStatus(true));
        dispatch(updateIsLoading(false));
        BurgerMenuDispatcher.then(
            ({ default: dispatcher }) => dispatcher.updateMyAccountMenuState(false, dispatch)
        );

        return {
            result: true,
            response
        };
    }

    /**
     * Sign in the user.
     * @param {Object} options - The sign-in options.
     * @param {Function} dispatch - The dispatch function.
     * @returns {Promise} The sign-in result.
     */
    signIn(options = {}, dispatch) {
        const result = super.signIn(options, dispatch);
        result.then(
            /** @namespace EroswholesaleScandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/signIn/result/then */
            () => BurgerMenuDispatcher.then(
                ({ default: dispatcher }) => dispatcher.updateMyAccountMenuState(false, dispatch)
            )
        );

        return result;
    }

    /**
     * Set the loading state.
     * @param {boolean} isLoading - The loading state.
     * @param {Function} dispatch - The dispatch function.
     */
    setIsLoading(isLoading, dispatch) {
        dispatch(updateIsLoading(isLoading));
    }

    /**
     * Request customer data and update the state.
     * @param {Function} dispatch - The dispatch function.
     * @returns {Promise<void>} The request result.
     */
    requestCustomerData(dispatch) {
        const query = MyAccountQuery.getCustomerQuery();

        return executePost(prepareQuery([query])).then(
            /** @namespace EroswholesaleScandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then */
            ({ customer }) => {
                if (!getAuthorizationToken()) {
                    return;
                }

                const { addresses = [] } = customer;

                const defaultShippingIndex = addresses.findIndex((address) => address.default_shipping);
                const defaultBillingIndex = addresses.findIndex((address) => address.default_billing);

                const reorderedAddresses = [];

                if (defaultShippingIndex !== -1) {
                    const [defaultShipping] = addresses.splice(defaultShippingIndex, 1);
                    if (defaultShipping) {
                        reorderedAddresses.push(defaultShipping);
                    }
                }

                if (defaultBillingIndex !== -1) {
                    const [defaultBilling] = addresses.splice(defaultBillingIndex, 1);
                    if (defaultBilling) {
                        reorderedAddresses.push(defaultBilling);
                    }
                }

                reorderedAddresses.push(...addresses.filter(Boolean));
                customer.addresses = Array.from(reorderedAddresses);

                dispatch(updateIsLocked(false));
                dispatch(updateCustomerDetails(customer));
                BrowserDatabase.setItem(customer, CUSTOMER, ONE_MONTH_IN_SECONDS);
            },
            /** @namespace EroswholesaleScandipwa/Store/MyAccount/Dispatcher/MyAccountDispatcher/requestCustomerData/executePost/then/catch */
            (error) => {
                const { extensions: { category } } = error[0];

                if (category === GRAPHQL_AUTH) {
                    dispatch(updateIsLocked(true));
                }
                dispatch(showNotification('error', getErrorMessage(error)));
            }
        );
    }
}

export default new MyAccountDispatcher();
