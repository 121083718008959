/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    getAddPaymentInfoEventData,
    getAddShippingInfoEventData,
    getBeginCheckoutEventData,
    getViewedShippingMethodsEventData
} from '../data/checkout';
import { getCustomerData } from '../data/customer';
import { getPurchaseEventData } from '../data/purchase';
import {
    GTM_EVENT_KEY_ADD_PAYMENT_INFO,
    GTM_EVENT_KEY_ADD_SHIPPING_INFO,
    GTM_EVENT_KEY_BEGIN_CHECKOUT,
    GTM_EVENT_KEY_CART_VIEWED_SHIPPING_METHODS,
    GTM_EVENT_KEY_CHECKOUT_VIEWED_SHIPPING_METHODS,
    GTM_EVENT_KEY_PURCHASE
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/Checkout/fireBeginCheckoutEvent */
export const fireBeginCheckoutEvent = debounceCallback(async () => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_BEGIN_CHECKOUT,
        customerId,
        ...await getBeginCheckoutEventData()
    });
});

/** @namespace Scandiweb/Gtm/Event/Checkout/fireAddShippingInfoEvent */
export const fireAddShippingInfoEvent = debounceCallback(async (shippingMethods, selectedShippingMethod) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_ADD_SHIPPING_INFO,
        customerId,
        ...await getAddShippingInfoEventData(shippingMethods, selectedShippingMethod)
    });
});

/** @namespace Scandiweb/Gtm/Event/Checkout/fireAddPaymentInfoEvent */
export const fireAddPaymentInfoEvent = debounceCallback(async (paymentMethods, selectedPaymentMethod) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_ADD_PAYMENT_INFO,
        customerId,
        ...await getAddPaymentInfoEventData(paymentMethods, selectedPaymentMethod)
    });
});

/** @namespace Scandiweb/Gtm/Event/Checkout/firePurchaseEvent */
export const firePurchaseEvent = debounceCallback(async (order) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_PURCHASE,
        customerId,
        ...await getPurchaseEventData(order)
    });
});

/** @namespace Scandiweb/Gtm/Event/Checkout/fireCheckoutViewedShippingMethodsEvent */
export const fireCheckoutViewedShippingMethodsEvent = async (shippingMethods) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_CHECKOUT_VIEWED_SHIPPING_METHODS,
        customerId,
        ...await getViewedShippingMethodsEventData(shippingMethods)
    });
};

/** @namespace Scandiweb/Gtm/Event/Checkout/fireCartViewedShippingMethodsEvent */
export const fireCartViewedShippingMethodsEvent = async (shippingMethods) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_CART_VIEWED_SHIPPING_METHODS,
        customerId,
        ...await getViewedShippingMethodsEventData(shippingMethods)
    });
};
