import _ from 'lodash';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';

/** @namespace EroswholesaleScandipwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    /**
     * PWA-190 If we don't remove meta title here, it gets rendered twice
     *
     * @return {{}[]|*}
     * @private
     */
    _getMetadata() {
        return _.filter(super._getMetadata(), ({ name }) => name !== 'title');
    }

    containerProps() {
        return {
            ...super.containerProps(),
            metadata: this._getMetadata()
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
