import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import CustomAssortmentMenu from 'Component/CustomAssortmentMenu';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    RouterContainer as SourceRouterContainer
} from 'SourceComponent/Router/Router.container';
import { isCart } from 'Util/Cart/Cart';
import { isCheckout } from 'Util/Checkout/Checkout';
import history from 'Util/History';

export const ShippingEstimatorDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/ShippingEstimator/ShippingEstimator.dispatcher'
    );

export const CustomAssortmentMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CustomAssortmentMenu/CustomAssortmentMenu.dispatcher'
    );

export const BurgerMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/BurgerMenu/BurgerMenu.dispatcher'
    );

export const CheckoutAuthnetcimDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CheckoutAuthnetcim/CheckoutAuthnetcim.dispatcher'
    );

/** @namespace EroswholesaleScandipwa/Component/Router/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cmsHomePage: state.ConfigReducer.cms_home_page
});

/** @namespace EroswholesaleScandipwa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    resetShippingEstimatorData: () => ShippingEstimatorDispatcher.then(
        ({ default: dispatcher }) => dispatcher.reset(dispatch)
    ),
    resetCustomAssortmentMenuSelections: () => CustomAssortmentMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.reset(dispatch)
    ),
    closeAllMenus: () => BurgerMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.closeAllMenus(dispatch)
    ),
    resetAuthnetcimData: () => CheckoutAuthnetcimDispatcher.then(
        ({ default: dispatcher }) => dispatcher.reset(dispatch)
    )
});

/** @namespace EroswholesaleScandipwa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    static propTypes = {
        ...SourceRouterContainer.propTypes,
        resetShippingEstimatorData: PropTypes.func.isRequired,
        resetCustomAssortmentMenuSelections: PropTypes.func.isRequired,
        resetAuthnetcimData: PropTypes.func.isRequired,
        closeAllMenus: PropTypes.func.isRequired,
        cmsHomePage: PropTypes.string.isRequired
    };

    containerProps() {
        const { meta_title } = super.containerProps();
        const { cmsHomePage } = this.props;
        if (!_.isUndefined(meta_title)) {
            return super.containerProps();
        }

        return {
            ...super.containerProps(),
            meta_title: {},
            cmsHomePage
        };
    }

    componentDidMount() {
        const {
            resetShippingEstimatorData,
            resetAuthnetcimData,
            resetCustomAssortmentMenuSelections,
            closeAllMenus
        } = this.props;

        super.componentDidMount();

        // TODO need to unset shipping address and shipping rate when navigating away
        history.listen((_history) => {
            /**
             * Whenever we navigate away from cart page, reset the reducer stored data
             * If we don't do this, the old data will show on initial page load
             */
            if (!isCart()) {
                resetShippingEstimatorData();
            }

            if (!isCheckout()) {
                resetAuthnetcimData();
            }

            /** Set all burger menus closed whenever the url changes */
            closeAllMenus();
            resetCustomAssortmentMenuSelections();
        });
    }

    componentDidUpdate(prevProps) {
        const { isLoading, updateMeta } = this.props;
        const { isLoading: prevIsLoading } = prevProps;

        if (!this.handleCheckIfOnlyMainItemsRender()) {
            this.setRenderAllItems();
        } else {
            this.setRenderOnlyMainItems();
        }

        if (!isLoading && isLoading !== prevIsLoading) {
            const {
                default_description,
                default_keywords,
                default_title,
                title_prefix,
                title_suffix,
                meta_title,
                status_code
            } = this.props;

            if (!meta_title) {
                return;
            }

            const { value: metaTitle = meta_title } = meta_title;

            updateMeta({
                default_title,
                title: metaTitle || default_title,
                default_description,
                description: default_description,
                default_keywords,
                keywords: default_keywords,
                title_prefix,
                title_suffix,
                status_code
            });
        }
    }

    render() {
        return (
            <>
                <CustomAssortmentMenu />
                { super.render() }
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
