/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/findApplicablePriceTier */
export const findApplicablePriceTier = (caseQty, priceTiers) => {
    if (!Array.isArray(priceTiers)) {
        return {};
    }

    const filteredPriceTiers = priceTiers
        .sort((a, b) => a.minimum_case_qty - b.minimum_case_qty)
        .reverse()
        .filter(({ minimum_case_qty }) => caseQty >= minimum_case_qty);

    return (filteredPriceTiers && filteredPriceTiers[0]) || {};
};

/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/getPricePerItem */
export const getPricePerItem = (product, caseQty) => {
    const { bulk_product_price_tiers } = product;
    const { price_per_item } = findApplicablePriceTier(caseQty, bulk_product_price_tiers);

    return price_per_item || 0;
};

/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/getFinalPricePerItem */
export const getFinalPricePerItem = (product, caseQty) => {
    const { bulk_product_price_tiers } = product;
    const { final_price_per_item } = findApplicablePriceTier(caseQty, bulk_product_price_tiers);

    return final_price_per_item || 0;
};

/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/getPricePerCase */
export const getPricePerCase = (product, caseQty) => {
    const { bulk_product_price_tiers } = product;
    const { price_per_case } = findApplicablePriceTier(caseQty, bulk_product_price_tiers);

    return price_per_case || 0;
};

/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/getFinalPricePerCase */
export const getFinalPricePerCase = (product, caseQty) => {
    const { bulk_product_price_tiers } = product;
    const { final_price_per_case } = findApplicablePriceTier(caseQty, bulk_product_price_tiers);

    return final_price_per_case || 0;
};

/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/getTotalItemQuantity */
export const getTotalItemQuantity = (product, caseQty) => {
    const { bulk_product_case_info: { items_per_case = 1 } = {} } = product;

    return caseQty * items_per_case;
};

/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/getFinalTotalPrice */
export const getFinalTotalPrice = (product, caseQty) => caseQty * getFinalPricePerCase(product, caseQty);

/** @namespace EroswholesaleScandipwa/Util/BulkProduct/Calculator/getUnitOfMeasureLabel */
export const getUnitOfMeasureLabel = (product, caseQty) => {
    const { bulk_product_case_info: { unit_of_measure, units_of_measure } } = product;

    return getTotalItemQuantity(product, caseQty) === 1 ? unit_of_measure : units_of_measure;
};
