import _ from 'lodash';

/** @namespace EroswholesaleScandipwa/Util/CustomAssortment/Calculator/roundToNearestIncrementValue */
export const roundToNearestIncrementValue = (userInputQty, incrementValue, maxQuantity) => {
    const floor = _.floor(userInputQty / incrementValue);
    const lowerLimit = floor * incrementValue;

    const ceil = _.ceil(userInputQty / incrementValue);
    const upperLimit = ceil * incrementValue;

    if (maxQuantity < lowerLimit) {
        return maxQuantity;
    }

    if (maxQuantity < upperLimit) {
        return lowerLimit;
    }

    if (lowerLimit === 0) {
        return upperLimit;
    }

    const lowerDifferential = userInputQty - lowerLimit;
    const upperDifferential = upperLimit - userInputQty;
    if (lowerDifferential === upperDifferential) {
        return upperLimit;
    }

    if (lowerDifferential < upperDifferential) {
        return lowerLimit;
    }

    return upperLimit;
};
