/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import {
    getAddToCartData,
    getRemoveFromCartData,
    getViewCartData
} from '../data/cart';
import { getCustomerData } from '../data/customer';
import {
    GTM_EVENT_KEY_ADD_TO_CART,
    GTM_EVENT_KEY_REMOVE_FROM_CART,
    GTM_EVENT_KEY_VIEW_CART
} from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/Cart/fireViewCartEvent */
export const fireViewCartEvent = debounceCallback(async () => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_VIEW_CART,
        customerId,
        ...await getViewCartData()
    });
});

/** @namespace Scandiweb/Gtm/Event/Cart/fireAddToCartEvent */
export const fireAddToCartEvent = debounceCallback(async (item, categories) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_ADD_TO_CART,
        customerId,
        ...await getAddToCartData(item, categories)
    });
});

/** @namespace Scandiweb/Gtm/Event/Cart/fireRemoveFromCartEvent */
export const fireRemoveFromCartEvent = debounceCallback(async (item) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_REMOVE_FROM_CART,
        customerId,
        ...await getRemoveFromCartData(item)
    });
});
