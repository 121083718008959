import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CustomAssortmentOptionsType } from 'Type/CustomAssortmentProduct.type';

import CustomAssortmentMenuOptionsComponent from './CustomAssortmentMenuOptions.component';

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOptions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    incrementValue: state.CustomAssortmentMenuReducer.incrementValue
});
/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOptions/Container/mapDispatchToProps */
export const mapDispatchToProps = (_dispatch) => ({});

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOptions/Container */
export class CustomAssortmentMenuOptionsContainer extends PureComponent {
    static propTypes = {
        options: CustomAssortmentOptionsType.isRequired,
        incrementValue: PropTypes.number.isRequired,
        remainingQty: PropTypes.number.isRequired
    };

    containerProps() {
        const { options, incrementValue, remainingQty } = this.props;

        return {
            options,
            incrementValue,
            remainingQty
        };
    }

    render() {
        return <CustomAssortmentMenuOptionsComponent { ...this.containerProps() } />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAssortmentMenuOptionsContainer);
