import PropTypes from 'prop-types';

import AddIcon from 'Component/AddIcon';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import MinusIcon from 'Component/MinusIcon';
import { FieldNumber as SourceFieldNumber } from 'SourceComponent/FieldNumber/FieldNumber.component';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import './FieldNumber.override.style';

/** @namespace EroswholesaleScandipwa/Component/FieldNumber/Component */
export class FieldNumberComponent extends SourceFieldNumber {
    static defaultProps = {
        ...SourceFieldNumber.defaultProps,
        label: 'Cases',
        handleMinusIconClick: null,
        handlePlusIconClick: null
    };

    static propTypes = {
        ...SourceFieldNumber.propTypes,
        label: PropTypes.string,
        events: PropTypes.object.isRequired,
        stateValue: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]).isRequired,
        handleMinusIconClick: PropTypes.func,
        handlePlusIconClick: PropTypes.func
    };

    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            events,
            value,
            setRef,
            stateValue,
            isDisabled,
            handleValueChange,
            handleMinusIconClick,
            handlePlusIconClick,
            label
        } = this.props;

        const numberValue = +value || +stateValue;

        return (
            <>
                <span
                    block="FieldNumber"
                    elem="Label"
                >
                    { label }
                </span>
                <div
                    block="FieldNumber"
                    elem="InputWrapper"
                >
                    <input
                        block="FieldNumber"
                        elem="Input"
                        ref={ (elem) => setRef(elem) }
                        { ...attr }
                        { ...events }
                        value={ value }
                        type={ FIELD_TYPE.number }
                        aria-label={ __('Value') }
                        disabled={ isDisabled }
                    />
                    <button
                        block="FieldNumber"
                        elem="Button"
                        disabled={ max === 1 || numberValue >= max || isDisabled }
                        aria-label={ __('Add') }
                        type={ FIELD_TYPE.button }
                        className="AddButton"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={ () => {
                            if (!handlePlusIconClick) {
                                return handleValueChange(numberValue + 1);
                            }

                            return handlePlusIconClick(numberValue);
                        } }
                    >
                        <AddIcon block="AddButton" isPrimary />
                    </button>
                    <button
                        block="FieldNumber"
                        elem="Button"
                        disabled={ numberValue + 1 === min || numberValue <= min || isDisabled }
                        aria-label={ __('Subtract') }
                        type={ FIELD_TYPE.button }
                        className="MinusButton"
                        // eslint-disable-next-line react/jsx-no-bind
                        onClick={ () => {
                            if (!handleMinusIconClick) {
                                return handleValueChange(numberValue - 1);
                            }

                            return handleMinusIconClick(numberValue);
                        } }
                    >
                        <MinusIcon block="SubtractButton" isPrimary />
                    </button>
                </div>
            </>
        );
    }
}

export default FieldNumberComponent;
