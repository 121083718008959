/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

export const GTM_EVENT_KEY_GENERAL = 'general';
export const GTM_EVENT_KEY_SEARCH = 'search';
export const GTM_EVENT_KEY_USER_LOGIN = 'login';
export const GTM_EVENT_KEY_USER_SIGN_UP = 'sign_up';
export const GTM_EVENT_KEY_ADD_TO_CART = 'add_to_cart';
export const GTM_EVENT_KEY_REMOVE_FROM_CART = 'remove_from_cart';
export const GTM_EVENT_KEY_VIEW_ITEM = 'view_item';
export const GTM_EVENT_KEY_VIEW_CART = 'view_cart';
export const GTM_EVENT_KEY_BEGIN_CHECKOUT = 'begin_checkout';
export const GTM_EVENT_KEY_ADD_SHIPPING_INFO = 'add_shipping_info';
export const GTM_EVENT_KEY_ADD_PAYMENT_INFO = 'add_payment_info';
export const GTM_EVENT_KEY_PURCHASE = 'purchase';
export const GTM_EVENT_KEY_CHECKOUT_VIEWED_SHIPPING_METHODS = 'checkout_viewed_shipping_methods';
export const GTM_EVENT_KEY_CART_VIEWED_SHIPPING_METHODS = 'cart_viewed_shipping_methods';
