export const SET_SEARCH_CRITERIA = 'SET_SEARCH_CRITERIA';
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS';
export const SET_HAS_SEARCH_RESULTS = 'SET_HAS_SEARCH_RESULTS';
export const SET_SEARCH_RESULTS_KEYS = 'SET_SEARCH_RESULTS_KEYS';
export const SET_SELECTED_KEY = 'SET_SELECTED_KEY';
export const SET_IS_LOADING = 'SET_IS_LOADING';

/** @namespace EroswholesaleScandipwa/Store/Klevu/Action/setSearchCriteria */
export const setSearchCriteria = (searchCriteria) => ({
    type: SET_SEARCH_CRITERIA,
    searchCriteria
});

/** @namespace EroswholesaleScandipwa/Store/Klevu/Action/setSearchResults */
export const setSearchResults = (searchResults) => ({
    type: SET_SEARCH_RESULTS,
    searchResults
});

/** @namespace EroswholesaleScandipwa/Store/Klevu/Action/setHasSearchResults */
export const setHasSearchResults = (hasSearchResults) => ({
    type: SET_HAS_SEARCH_RESULTS,
    hasSearchResults
});

/** @namespace EroswholesaleScandipwa/Store/Klevu/Action/setSearchResultsKeys */
export const setSearchResultsKeys = (searchResultsKeys) => ({
    type: SET_SEARCH_RESULTS_KEYS,
    searchResultsKeys: searchResultsKeys || []
});

/** @namespace EroswholesaleScandipwa/Store/Klevu/Action/setSelectedKey */
export const setSelectedKey = (selectedKey) => ({
    type: SET_SELECTED_KEY,
    selectedKey: selectedKey || ''
});

/** @namespace EroswholesaleScandipwa/Store/Klevu/Action/setIsLoading */
export const setIsLoading = (isLoading) => ({
    type: SET_IS_LOADING,
    isLoading
});
