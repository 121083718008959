import { ADD_TO_FEATURED_PRODUCTS } from './CategoryFeaturedProducts.action';

/** @namespace EroswholesaleScandipwa/Store/CategoryFeaturedProducts/Reducer/getInitialState */
export const getInitialState = () => ({
    featuredProducts: {},
    isFetchingFeaturedProducts: false
});

/** @namespace EroswholesaleScandipwa/Store/CategoryFeaturedProducts/Reducer/CategoryFeaturedProductsReducer */
export const CategoryFeaturedProductsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case ADD_TO_FEATURED_PRODUCTS:
            const { featuredProducts } = action;
            // eslint-disable-next-line fp/no-let
            let { featuredProducts: existingFeaturedProducts } = state;
            existingFeaturedProducts = {
                ...existingFeaturedProducts,
                ...featuredProducts
            };

            return {
                ...state,
                featuredProducts: existingFeaturedProducts
            };
        default:
            return state;
    }
};

export default CategoryFeaturedProductsReducer;
