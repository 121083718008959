import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import history from 'Util/History';

/** @namespace EroswholesaleScandipwa/Util/Checkout/isCheckout */
export const isCheckout = () => {
    const {
        location: {
            pathname
        }
    } = history;

    return pathname.includes(CHECKOUT_URL);
};
