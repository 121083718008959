import PropTypes from 'prop-types';

export const mixType = PropTypes.oneOfType([
    PropTypes.arrayOf(
        PropTypes.shape({
            block: PropTypes.string,
            elem: PropTypes.string
        })
    ),
    PropTypes.shape({
        block: PropTypes.string,
        elem: PropTypes.string
    })
]);
