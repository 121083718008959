import { getMaxQuantity as sourceGetMaxQuantity } from 'SourceUtil/Product/Extract';

export * from 'SourceUtil/Product/Extract';

/** @namespace EroswholesaleScandipwa/Util/Product/Extract/getMaxQuantity */
export const getMaxQuantity = (product, configIndex = -1) => {
    const { salable_case_qty } = product;
    if (salable_case_qty === null || salable_case_qty === undefined) {
        return sourceGetMaxQuantity(product, configIndex);
    }

    return salable_case_qty;
};
