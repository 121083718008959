/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireLoginEvent, fireSignUpEvent } from '../../event/customer';

const addFireLoginEvent = (args, callback, _instance) => callback(...args).finally((result) => {
    try {
        if (result) {
            fireLoginEvent();
        }
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }
});

const addFireFirebaseLoginEvent = (args, callback, _instance) => callback(...args)
    .finally(({ result, response: { credential: { signInMethod } = {} } = {} } = {}) => {
        try {
            if (result) {
                fireLoginEvent(signInMethod);
            }
        } catch (e) {
            console.error('An error occurred while trying to transmit GTM data.', e);
        }
    });

const addFireSignUpEvent = (args, callback, _instance) => callback(...args).finally(() => {
    try {
        fireSignUpEvent();
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }
});

export default {
    'Store/MyAccount/Dispatcher': {
        'member-function': {
            signIn: addFireLoginEvent,
            createAccount: addFireSignUpEvent
        }
    },
    'EroswholesaleScandipwa/Store/MyAccount/Dispatcher': {
        'member-function': {
            signInWithFirebaseAuth: addFireFirebaseLoginEvent
        }
    }
};
