import { UrlRewrites as SourceUrlRewrites } from 'SourceRoute/UrlRewrites/UrlRewrites.component';

/** @namespace EroswholesaleScandipwa/Route/UrlRewrites/Component */
export class UrlRewritesComponent extends SourceUrlRewrites {
    render() {
        /**
         * Removes <suspense> component since it causes a bit of CLS
         */
        return this.renderContent();
    }
}

export default UrlRewritesComponent;
