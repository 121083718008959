export const SET_STORED_CARD_LIST = 'SET_STORED_CARD_LIST';
export const SET_STORED_CARDS_FETCHED = 'SET_STORED_CARDS_FETCHED';
export const SET_CONFIG_FETCHED = 'SET_CONFIG_FETCHED';
export const SET_ACCEPTED_CARD_TYPES = 'SET_ACCEPTED_CARD_TYPES';
export const SET_AVAILABLE_MONTHS = 'SET_AVAILABLE_MONTHS';
export const SET_AVAILABLE_YEARS = 'SET_AVAILABLE_YEARS';
export const SET_CARD_NUMBER = 'SET_CARD_NUMBER';
export const SET_CARD_EXPIRATION_MONTH = 'SET_CARD_EXPIRATION_MONTH';
export const SET_CARD_EXPIRATION_YEAR = 'SET_CARD_EXPIRATION_YEAR';
export const SET_CARD_CCV = 'SET_CARD_CCV';
export const SET_CARD_TYPE = 'SET_CARD_TYPE';
export const SET_SELECTED_CARD_ID = 'SET_SELECTED_CARD_ID';
export const SET_SELECTED_CARD_CCV = 'SET_SELECTED_CARD_CCV';
export const SET_SAVE_CARD = 'SET_SAVE_CARD';

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setStoredCardList */
export const setStoredCardList = (storedCardList) => ({
    type: SET_STORED_CARD_LIST,
    storedCardList
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setStoredCardsFetched */
export const setStoredCardsFetched = (storedCardsFetched) => ({
    type: SET_STORED_CARDS_FETCHED,
    storedCardsFetched
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setConfigFetched */
export const setConfigFetched = (configFetched) => ({
    type: SET_CONFIG_FETCHED,
    configFetched
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setAcceptedCardTypes */
export const setAcceptedCardTypes = (acceptedCardTypes) => ({
    type: SET_ACCEPTED_CARD_TYPES,
    acceptedCardTypes
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setAvailableMonths */
export const setAvailableMonths = (availableMonths) => ({
    type: SET_AVAILABLE_MONTHS,
    availableMonths
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setAvailableYears */
export const setAvailableYears = (availableYears) => ({
    type: SET_AVAILABLE_YEARS,
    availableYears
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setCardNumber */
export const setCardNumber = (cardNumber) => ({
    type: SET_CARD_NUMBER,
    cardNumber
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setCardExpirationMonth */
export const setCardExpirationMonth = (cardExpirationMonth) => ({
    type: SET_CARD_EXPIRATION_MONTH,
    cardExpirationMonth
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setCardExpirationYear */
export const setCardExpirationYear = (cardExpirationYear) => ({
    type: SET_CARD_EXPIRATION_YEAR,
    cardExpirationYear
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setCardCcv */
export const setCardCcv = (cardCcv) => ({
    type: SET_CARD_CCV,
    cardCcv
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setCardType */
export const setCardType = (cardType) => ({
    type: SET_CARD_TYPE,
    cardType
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setSelectedCardId */
export const setSelectedCardId = (selectedCardId) => ({
    type: SET_SELECTED_CARD_ID,
    selectedCardId
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setSelectedCardCcv */
export const setSelectedCardCcv = (selectedCardCcv) => ({
    type: SET_SELECTED_CARD_CCV,
    selectedCardCcv
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Action/setSaveCard */
export const setSaveCard = (saveCard) => ({
    type: SET_SAVE_CARD,
    saveCard
});
