import { SET_MAX_VALUE, SET_MIN_VALUE } from './PriceRange.action';

/** @namespace EroswholesaleScandipwa/Store/PriceRange/Reducer/getInitialState */
export const getInitialState = () => ({
    minValue: null,
    maxValue: null
});

/** @namespace EroswholesaleScandipwa/Store/PriceRange/Reducer/PriceRangeReducer */
export const PriceRangeReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case SET_MIN_VALUE:
            const { minValue } = action;

            return {
                ...state,
                minValue
            };

        case SET_MAX_VALUE:
            const { maxValue } = action;

            return {
                ...state,
                maxValue
            };

        default:
            return state;
    }
};

export default PriceRangeReducer;
