/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { isCart } from 'Util/Cart/Cart';
import getStore from 'Util/Store';

import { fireAddToCartEvent, fireRemoveFromCartEvent, fireViewCartEvent } from '../../event/cart';
import { getCategoriesData } from '../../util/breadcrumbs';
import { waitForCallback } from '../../util/wait';

const addFireViewCartEvent = (args, callback) => {
    const result = callback(...args);

    try {
        if (isCart()) {
            fireViewCartEvent();
        }
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }

    return result;
};

const addFireViewMiniCartEvent = (args, callback, instance) => {
    const result = callback(...args);

    try {
        const prevProps = args[0];
        const { props: { isOpen } } = instance;
        const { isOpen: wasOpen } = prevProps;

        if (isOpen && isOpen !== wasOpen) {
            fireViewCartEvent();
        }
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }

    return result;
};

const addFireAddToCartEvent = async (args, callback, _instance) => callback(...args).finally(async () => {
    try {
        await waitForCallback(() => getStore().getState().CartItemReducer.lastAddedOrUpdatedItemId);
        const itemId = getStore().getState().CartItemReducer.lastAddedOrUpdatedItemId;

        await waitForCallback(() => getStore().getState().CartReducer.cartTotals);
        const { cartTotals: { items } } = getStore().getState().CartReducer;
        const item = items.find((item) => item.item_id === itemId);

        const breadcrumbs = await getCategoriesData();
        fireAddToCartEvent(item, breadcrumbs);
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }
});

const addFireRemoveFromCartEvent = async (args, callback, _instance) => {
    await waitForCallback(() => getStore().getState().CartReducer?.cartTotals?.items);
    const items = getStore().getState().CartReducer?.cartTotals?.items;

    return callback(...args).finally((_response) => {
        try {
            const item = items.find((item) => item.item_id === args[1]);
            fireRemoveFromCartEvent(item);
        } catch (e) {
            console.error('An error occurred while trying to transmit GTM data.', e);
        }
    });
};

export default {
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart: addFireAddToCartEvent,
            removeProductFromCart: addFireRemoveFromCartEvent
        }
    },
    'Route/CartPage/Component': {
        'member-function': {
            componentDidMount: addFireViewCartEvent
        }
    },
    'Component/CartOverlay/Container': {
        'member-function': {
            componentDidUpdate: addFireViewMiniCartEvent
        }
    }
};
