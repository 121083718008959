// import Image from 'Component/Image';
import { CloseIcon as SourceCloseIcon } from 'SourceComponent/CloseIcon/CloseIcon.component';

// import closeIcon from 'Style/icons/BurgerMenu/Close.svg';
import './CloseIcon.override.style';

/** @namespace EroswholesaleScandipwa/Component/CloseIcon/Component */
export class CloseIconComponent extends SourceCloseIcon {
    /* render() {
        return (
            <Image
                className="CloseIcon"
                src={ closeIcon }
                alt="Close icon"
            />
        );
    } */
}

export default CloseIconComponent;
