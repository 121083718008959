import parse from 'html-react-parser';

import {
    appendPage as sourceAppendPage,
    updateProductListItems as sourceUpdateProductListItems
} from 'SourceStore/ProductList/ProductList.action';

export * from 'SourceStore/ProductList/ProductList.action';

/** @namespace EroswholesaleScandipwa/Store/ProductList/Action/updateProductListItems */
export const updateProductListItems = (
    items,
    currentPage,
    total_count,
    total_pages,
    args
) => ({
    ...sourceUpdateProductListItems(items, currentPage, total_count, total_pages, args),
    items: items.map((item) => ({ ...item, name: String(parse(item.name)) }))
});

/** @namespace EroswholesaleScandipwa/Store/ProductList/Action/appendPage */
export const appendPage = (items, currentPage) => ({
    ...sourceAppendPage(items, currentPage),
    items: items.map((item) => ({ ...item, name: String(parse(item.name)) }))
});
