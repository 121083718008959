import {
    SET_ACCEPTED_CARD_TYPES,
    SET_AVAILABLE_MONTHS,
    SET_AVAILABLE_YEARS,
    SET_CARD_CCV,
    SET_CARD_EXPIRATION_MONTH,
    SET_CARD_EXPIRATION_YEAR,
    SET_CARD_NUMBER,
    SET_CARD_TYPE,
    SET_CONFIG_FETCHED,
    SET_SAVE_CARD,
    SET_SELECTED_CARD_CCV,
    SET_SELECTED_CARD_ID,
    SET_STORED_CARD_LIST,
    SET_STORED_CARDS_FETCHED
} from './CheckoutAuthnetcim.action';

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Reducer/getInitialState */
export const getInitialState = () => ({
    storedCardList: [],
    storedCardsFetched: false,
    configFetched: false,
    acceptedCardTypes: [],
    availableMonths: [],
    availableYears: [],
    cardNumber: '',
    cardCcv: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    cardType: '',
    selectedCardId: '',
    selectedCardCcv: '',
    saveCard: false
});

/** @namespace EroswholesaleScandipwa/Store/CheckoutAuthnetcim/Reducer/CheckoutAuthnetcimReducer */
export const CheckoutAuthnetcimReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case SET_STORED_CARD_LIST:
            const { storedCardList } = action;

            return {
                ...state,
                storedCardList
            };
        case SET_STORED_CARDS_FETCHED:
            const { storedCardsFetched } = action;

            return {
                ...state,
                storedCardsFetched
            };
        case SET_CONFIG_FETCHED:
            const { configFetched } = action;

            return {
                ...state,
                configFetched
            };
        case SET_ACCEPTED_CARD_TYPES:
            const { acceptedCardTypes } = action;

            return {
                ...state,
                acceptedCardTypes
            };
        case SET_AVAILABLE_MONTHS:
            const { availableMonths } = action;

            return {
                ...state,
                availableMonths
            };
        case SET_AVAILABLE_YEARS:
            const { availableYears } = action;

            return {
                ...state,
                availableYears
            };
        case SET_CARD_NUMBER:
            const { cardNumber } = action;

            return {
                ...state,
                cardNumber
            };
        case SET_CARD_CCV:
            const { cardCcv } = action;

            return {
                ...state,
                cardCcv
            };
        case SET_CARD_TYPE:
            const { cardType } = action;

            return {
                ...state,
                cardType
            };
        case SET_CARD_EXPIRATION_MONTH:
            const { cardExpirationMonth } = action;

            return {
                ...state,
                cardExpirationMonth
            };
        case SET_CARD_EXPIRATION_YEAR:
            const { cardExpirationYear } = action;

            return {
                ...state,
                cardExpirationYear
            };
        case SET_SELECTED_CARD_ID:
            const { selectedCardId } = action;

            return {
                ...state,
                selectedCardId
            };
        case SET_SELECTED_CARD_CCV:
            const { selectedCardCcv } = action;

            return {
                ...state,
                selectedCardCcv
            };
        case SET_SAVE_CARD:
            const { saveCard } = action;

            return {
                ...state,
                saveCard
            };
        default:
            return state;
    }
};

export default CheckoutAuthnetcimReducer;
