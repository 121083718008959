export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_RATES = 'SET_RATES';
export const SET_SELECTED_SHIPPING_METHOD = 'SET_SELECTED_SHIPPING_METHOD';
export const SET_USER_HAS_CHANGED = 'SET_USER_HAS_CHANGED';
export const RESET = 'RESET';

/** @namespace EroswholesaleScandipwa/Store/ShippingEstimator/Action/setAddress */
export const setAddress = (address) => ({
    type: SET_ADDRESS,
    address
});

/** @namespace EroswholesaleScandipwa/Store/ShippingEstimator/Action/setRates */
export const setRates = (rates) => ({
    type: SET_RATES,
    rates
});

/** @namespace EroswholesaleScandipwa/Store/ShippingEstimator/Action/setSelectedShippingMethod */
export const setSelectedShippingMethod = (rate) => ({
    type: SET_SELECTED_SHIPPING_METHOD,
    selectedShippingMethod: rate
});

/** @namespace EroswholesaleScandipwa/Store/ShippingEstimator/Action/setUserHasChanged */
export const setUserHasChanged = (bool) => ({
    type: SET_USER_HAS_CHANGED,
    userHasChanged: bool
});

/** @namespace EroswholesaleScandipwa/Store/ShippingEstimator/Action/reset */
export const reset = () => ({
    type: RESET
});
