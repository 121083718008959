import { freezeScroll, unfreezeScroll } from 'Util/Browser';

import {
    TOGGLE_CUSTOM_ASSORTMENT_MENU,
    TOGGLE_MAIN_MENU,
    TOGGLE_MINI_CART,
    TOGGLE_MY_ACCOUNT_MENU,
    TOGGLE_PLP_FILTER_MENU,
    TOGGLE_SEARCH_MENU
} from './BurgerMenu.action';
/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    isMainMenuOpen: false,
    isSearchMenuOpen: false,
    isMiniCartOpen: false,
    isPlpFilterMenuOpen: false,
    isCustomAssortmentMenuOpen: false,
    isMyAccountMenuOpen: false
});

/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Reducer/BurgerMenuReducer */
export const BurgerMenuReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case TOGGLE_MAIN_MENU:
            const { isMainMenuOpen } = action;
            // eslint-disable-next-line no-unused-expressions
            isMainMenuOpen ? freezeScroll() : unfreezeScroll();

            return {
                ...state,
                isMainMenuOpen
            };

        case TOGGLE_SEARCH_MENU:
            const { isSearchMenuOpen } = action;
            // eslint-disable-next-line no-unused-expressions
            isSearchMenuOpen ? freezeScroll() : unfreezeScroll();

            return {
                ...state,
                isSearchMenuOpen
            };

        case TOGGLE_MINI_CART:
            const { isMiniCartOpen } = action;
            // eslint-disable-next-line no-unused-expressions
            isMiniCartOpen ? freezeScroll() : unfreezeScroll();

            return {
                ...state,
                isMiniCartOpen
            };

        case TOGGLE_PLP_FILTER_MENU:
            const { isPlpFilterMenuOpen } = action;
            // eslint-disable-next-line no-unused-expressions
            isPlpFilterMenuOpen ? freezeScroll() : unfreezeScroll();

            return {
                ...state,
                isPlpFilterMenuOpen
            };

        case TOGGLE_CUSTOM_ASSORTMENT_MENU:
            const { isCustomAssortmentMenuOpen } = action;
            // eslint-disable-next-line no-unused-expressions
            isCustomAssortmentMenuOpen ? freezeScroll() : unfreezeScroll();

            return {
                ...state,
                isCustomAssortmentMenuOpen
            };

        case TOGGLE_MY_ACCOUNT_MENU:
            const { isMyAccountMenuOpen } = action;
            // eslint-disable-next-line no-unused-expressions
            isMyAccountMenuOpen ? freezeScroll() : unfreezeScroll();

            return {
                ...state,
                isMyAccountMenuOpen
            };

        default:
            return state;
    }
};

export default BurgerMenuReducer;
