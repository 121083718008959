import { FIELD_TYPE } from 'SourceComponent/Field/Field.config';

export { FIELD_TYPE, FIELD_RADIO_NONE } from 'SourceComponent/Field/Field.config';

export const FLOATING_LABEL_FIELD_TYPES = [
    FIELD_TYPE.email,
    FIELD_TYPE.text,
    FIELD_TYPE.time,
    FIELD_TYPE.dateTime,
    FIELD_TYPE.date,
    FIELD_TYPE.password,
    FIELD_TYPE.submit,
    FIELD_TYPE.textarea,
    FIELD_TYPE.select
];

export default FIELD_TYPE;
