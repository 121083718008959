import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Field from 'Component/Field';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import { CustomAssortmentOptionType } from 'Type/CustomAssortmentProduct.type';
import { VALIDATION_INPUT_TYPE_NUMBER } from 'Util/Validator/Config';

import './CustomAssortmentMenuOption.style';

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOption/Component */
export class CustomAssortmentMenuOptionComponent extends PureComponent {
    static propTypes = {
        option: CustomAssortmentOptionType.isRequired,
        qty: PropTypes.number.isRequired,
        maxQuantity: PropTypes.number.isRequired,
        incrementValue: PropTypes.number.isRequired,
        isInStock: PropTypes.bool.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleBlur: PropTypes.func.isRequired,
        handlePlusIconClick: PropTypes.func.isRequired,
        handleMinusIconClick: PropTypes.func.isRequired,
        showRoundingMessage: PropTypes.bool.isRequired
    };

    render() {
        const {
            option: {
                id,
                label
            },
            qty,
            maxQuantity,
            incrementValue,
            isInStock,
            handleInputChange,
            handlePlusIconClick,
            handleMinusIconClick,
            handleBlur,
            showRoundingMessage
        } = this.props;

        return (
            <div
                block="CustomAssortmentMenuOption"
                className={ `${ !isInStock ? 'OutOfStock' : '' }` }
            >
                <p
                    block="CustomAssortmentMenuOption"
                    elem="Label"
                >
                    { label }
                </p>
                <Field
                    type={ FIELD_TYPE.number }
                    attr={ {
                        id: `item_qty-${ id }`,
                        name: `item_qty[${ id }]`,
                        defaultValue: qty,
                        min: 0,
                        max: maxQuantity
                    } }
                    validationRule={ {
                        inputType: VALIDATION_INPUT_TYPE_NUMBER.numeric,
                        isRequired: true,
                        range: {
                            min: 0
                        }
                    } }
                    events={ {
                        onChange: handleInputChange,
                        onBlur: handleBlur
                    } }
                    mix={ { block: 'CustomAssortmentMenuOption', elem: 'Qty' } }
                    validateOn={ ['onChange', 'onBlur'] }
                    value={ qty }
                    isDisabled={ !isInStock || !maxQuantity }
                    handlePlusIconClick={ handlePlusIconClick }
                    handleMinusIconClick={ handleMinusIconClick }
                />
                { showRoundingMessage && (
                    <div
                        block="CustomAssortmentMenuOption"
                        elem="RoundingMessageWrapper"
                    >
                        <span
                            block="CustomAssortmentMenuOption"
                            elem="RoundingMessage"
                        >
                            { `${ __('Rounded to nearest available increment of') } ${ incrementValue }` }
                        </span>
                    </div>
                ) }
            </div>
        );
    }
}

export default CustomAssortmentMenuOptionComponent;
