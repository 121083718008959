export const SET_LAST_ADDED_OR_UPDATED_ITEM_ID = 'SET_LAST_ADDED_OR_UPDATED_ITEM_ID';
export const SET_LAST_ADDED_OR_UPDATED_PRODUCT_ID = 'SET_LAST_ADDED_OR_UPDATED_PRODUCT_ID';

/** @namespace EroswholesaleScandipwa/Store/CartItem/Action/setLastAddedOrUpdatedItemId */
export const setLastAddedOrUpdatedItemId = (lastAddedOrUpdatedItemId) => ({
    type: SET_LAST_ADDED_OR_UPDATED_ITEM_ID,
    lastAddedOrUpdatedItemId
});

/** @namespace EroswholesaleScandipwa/Store/CartItem/Action/setLastAddedOrUpdatedProductId */
export const setLastAddedOrUpdatedProductId = (lastAddedOrUpdatedProductId) => ({
    type: SET_LAST_ADDED_OR_UPDATED_PRODUCT_ID,
    lastAddedOrUpdatedProductId
});
