/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import _ from 'lodash';

import getStore from 'Util/Store';

import {
    GTM_EVENT_KEY_ADD_PAYMENT_INFO,
    GTM_EVENT_KEY_ADD_SHIPPING_INFO,
    GTM_EVENT_KEY_ADD_TO_CART,
    GTM_EVENT_KEY_BEGIN_CHECKOUT,
    GTM_EVENT_KEY_CHECKOUT_VIEWED_SHIPPING_METHODS,
    GTM_EVENT_KEY_GENERAL,
    GTM_EVENT_KEY_PURCHASE,
    GTM_EVENT_KEY_REMOVE_FROM_CART,
    GTM_EVENT_KEY_SEARCH,
    GTM_EVENT_KEY_USER_LOGIN,
    GTM_EVENT_KEY_USER_SIGN_UP,
    GTM_EVENT_KEY_VIEW_CART,
    GTM_EVENT_KEY_VIEW_ITEM
} from './events';

/** @namespace Scandiweb/Gtm/Util/CheckIsEventEnable/checkIsEventEnable */
export const checkIsEventEnable = (event) => {
    const {
        events: {
            gtm_general_init,
            gtm_search,
            gtm_login,
            gtm_sign_up,
            gtm_product_add_to_cart,
            gtm_product_remove_from_cart,
            gtm_view_item,
            gtm_view_cart,
            gtm_begin_checkout,
            gtm_add_shipping_info,
            gtm_add_payment_info,
            gtm_purchase,
            gtm_checkout_viewed_shipping_methods
        } = {}
    } = getStore().getState().ConfigReducer.gtm;

    const eventsMap = {
        [GTM_EVENT_KEY_GENERAL]: gtm_general_init,
        [GTM_EVENT_KEY_SEARCH]: gtm_search,
        [GTM_EVENT_KEY_USER_LOGIN]: gtm_login,
        [GTM_EVENT_KEY_USER_SIGN_UP]: gtm_sign_up,
        [GTM_EVENT_KEY_ADD_TO_CART]: gtm_product_add_to_cart,
        [GTM_EVENT_KEY_REMOVE_FROM_CART]: gtm_product_remove_from_cart,
        [GTM_EVENT_KEY_VIEW_ITEM]: gtm_view_item,
        [GTM_EVENT_KEY_VIEW_CART]: gtm_view_cart,
        [GTM_EVENT_KEY_BEGIN_CHECKOUT]: gtm_begin_checkout,
        [GTM_EVENT_KEY_ADD_SHIPPING_INFO]: gtm_add_shipping_info,
        [GTM_EVENT_KEY_ADD_PAYMENT_INFO]: gtm_add_payment_info,
        [GTM_EVENT_KEY_PURCHASE]: gtm_purchase,
        [GTM_EVENT_KEY_CHECKOUT_VIEWED_SHIPPING_METHODS]: gtm_checkout_viewed_shipping_methods
    };

    if (!_.has(eventsMap, event)) {
        return true;
    }

    return eventsMap[event];
};
