import { Meta as SourceMeta } from 'SourceComponent/Meta/Meta.component';
import { isHomePageUrl } from 'Util/Url';

/** @namespace EroswholesaleScandipwa/Component/Meta/Component */
export class MetaComponent extends SourceMeta {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        // Remove prerendered meta tags so dynamic meta tags can take effect
        Array.prototype.slice.call(
            document.head.querySelectorAll('meta[data-prerendered]')
        ).forEach((tag) => {
            document.head.removeChild(tag);
        });
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    removePrerenderedTitle() {
        Array.prototype.slice.call(
            document.head.querySelectorAll('title[data-prerendered]')
        ).forEach((tag) => {
            document.head.removeChild(tag);
        });
    }

    renderTitle() {
        const { default_title, title } = this.props;
        const isHomepage = isHomePageUrl(window.location.pathname);

        if (!isHomepage && (!title || title === default_title)) {
            const titleElement = document.getElementsByTagName('title')[0];
            if (titleElement && titleElement.innerText) {
                return this.renderTitleElement(titleElement.innerText);
            }

            return null;
        }

        return this.renderTitleElement(title || default_title);
    }

    renderTitleElement(text) {
        const {
            title_prefix,
            title_suffix
        } = this.props;

        if (text) {
            this.removePrerenderedTitle();
            const titlePrefix = title_prefix ? `${ title_prefix } | ` : '';
            const titleSuffix = title_suffix ? ` | ${ title_suffix }` : '';

            return (
                <title>
                    { `${ titlePrefix }${ text }${ titleSuffix }` }
                </title>
            );
        }

        return null;
    }
}

export default MetaComponent;
