import { noopFn } from 'Util/Common';

import {
    SET_CASE_QUANTITY,
    SET_IDENTIFIER,
    SET_INCREMENT_VALUE,
    SET_ITEMS_PER_CASE,
    SET_LAST_IDENTIFIER,
    SET_OPTIONS,
    SET_PRODUCT,
    SET_SELECTION_CALLBACK,
    SET_SELECTIONS,
    SET_UNIT_OF_MEASURE,
    SET_UNITS_OF_MEASURE
} from './CustomAssortmentMenu.action';

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Reducer/getInitialState */
export const getInitialState = () => ({
    identifier: null,
    lastIdentifier: null,
    product: null,
    caseQuantity: 1,
    incrementValue: 1,
    itemsPerCase: 1,
    unitOfMeasure: 'Unit',
    unitsOfMeasure: 'Units',
    options: [],
    selections: {},
    setSelectionCallback: noopFn
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Reducer/CustomAssortmentMenuReducer */
export const CustomAssortmentMenuReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case SET_IDENTIFIER:
            const { identifier } = action;

            return {
                ...state,
                identifier
            };

        case SET_LAST_IDENTIFIER:
            const { lastIdentifier } = action;

            return {
                ...state,
                lastIdentifier
            };

        case SET_PRODUCT:
            const { product } = action;

            return {
                ...state,
                product
            };

        case SET_CASE_QUANTITY:
            const { caseQuantity } = action;

            return {
                ...state,
                caseQuantity
            };

        case SET_INCREMENT_VALUE:
            const { incrementValue } = action;

            return {
                ...state,
                incrementValue
            };

        case SET_ITEMS_PER_CASE:
            const { itemsPerCase } = action;

            return {
                ...state,
                itemsPerCase
            };

        case SET_UNIT_OF_MEASURE:
            const { unitOfMeasure } = action;

            return {
                ...state,
                unitOfMeasure
            };

        case SET_UNITS_OF_MEASURE:
            const { unitsOfMeasure } = action;

            return {
                ...state,
                unitsOfMeasure
            };

        case SET_OPTIONS:
            const { options } = action;

            return {
                ...state,
                options
            };

        case SET_SELECTIONS:
            const { selections } = action;

            return {
                ...state,
                selections
            };

        case SET_SELECTION_CALLBACK:
            const { setSelectionCallback } = action;

            return {
                ...state,
                setSelectionCallback
            };

        default:
            return state;
    }
};

export default CustomAssortmentMenuReducer;
