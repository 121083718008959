export const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU';
export const TOGGLE_SEARCH_MENU = 'TOGGLE_SEARCH_MENU';
export const TOGGLE_MINI_CART = 'TOGGLE_MINI_CART';
export const TOGGLE_PLP_FILTER_MENU = 'TOGGLE_PLP_FILTER_MENU';
export const TOGGLE_CUSTOM_ASSORTMENT_MENU = 'TOGGLE_CUSTOM_ASSORTMENT_MENU';
export const TOGGLE_MY_ACCOUNT_MENU = 'TOGGLE_MY_ACCOUNT_MENU';

/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Action/toggleMainMenu */
export const toggleMainMenu = (isMainMenuOpen) => ({
    type: TOGGLE_MAIN_MENU,
    isMainMenuOpen
});

/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Action/toggleSearchMenu */
export const toggleSearchMenu = (isSearchMenuOpen) => ({
    type: TOGGLE_SEARCH_MENU,
    isSearchMenuOpen
});

/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Action/toggleMiniCart */
export const toggleMiniCart = (isMiniCartOpen) => ({
    type: TOGGLE_MINI_CART,
    isMiniCartOpen
});

/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Action/togglePlpFilterMenu */
export const togglePlpFilterMenu = (isPlpFilterMenuOpen) => ({
    type: TOGGLE_PLP_FILTER_MENU,
    isPlpFilterMenuOpen
});

/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Action/toggleCustomAssortmentMenu */
export const toggleCustomAssortmentMenu = (isCustomAssortmentMenuOpen) => ({
    type: TOGGLE_CUSTOM_ASSORTMENT_MENU,
    isCustomAssortmentMenuOpen
});

/** @namespace EroswholesaleScandipwa/Store/BurgerMenu/Action/toggleMyAccountMenu */
export const toggleMyAccountMenu = (isMyAccountMenuOpen) => ({
    type: TOGGLE_MY_ACCOUNT_MENU,
    isMyAccountMenuOpen
});
