import { OrderQuery as SourceOrderQuery } from 'SourceQuery/Order.query';
import { Field } from 'Util/Query';

/** @namespace EroswholesaleScandipwa/Query/Order/Query */
export class OrderQuery extends SourceOrderQuery {
    getCustomerOrderByIncrementId(incrementId) {
        return new Field('customerOrderByIncrementId')
            .addArgument('incrementId', 'String!', incrementId)
            .addFieldList(this._getSingleOrderFields())
            .addField('coupon_code');
    }

    getGuestOrderByEmailCartIdIncrementId(email, cartId, incrementId) {
        return new Field('guestOrderByEmailCartIdIncrementId')
            .addArgument('email', 'String!', email)
            .addArgument('cartId', 'String!', cartId)
            .addArgument('incrementId', 'String!', incrementId)
            .addFieldList(this._getSingleOrderFields())
            .addField('coupon_code');
    }

    _getSingleOrderFields() {
        return [
            ...super._getSingleOrderFields(),
            this._getOrderItemTotalField(),
            'increment_id'
        ];
    }

    _getOrderItemsProductsField() {
        return super._getOrderItemsProductsField()
            .addField(this._getBulkProductCaseInfoField());
    }

    _getBulkProductCaseInfoField() {
        const fields = [
            'case_qty',
            'qty_per_case',
            'total_qty',
            'price_per_case',
            'price_per_case_formatted',
            'price_per_item',
            'price_per_item_formatted',
            'special_price_per_case',
            'special_price_per_case_formatted',
            'special_price_per_item',
            'special_price_per_item_formatted',
            'final_price_per_case',
            'final_price_per_case_formatted',
            'final_price_per_item',
            'final_price_per_item_formatted',
            'units_of_measure_label',
            'units_of_measure_plural_label',
            'label'
        ];

        return new Field('bulk_product_case_info')
            .addFieldList([
                ...fields,
                new Field('children')
                    .addFieldList(fields)
            ]);
    }
}

export default new OrderQuery();
