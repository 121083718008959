import {
    SET_HAS_SEARCH_RESULTS,
    SET_IS_LOADING,
    SET_SEARCH_CRITERIA,
    SET_SEARCH_RESULTS,
    SET_SEARCH_RESULTS_KEYS,
    SET_SELECTED_KEY
} from './Klevu.action';

/** @namespace EroswholesaleScandipwa/Store/Klevu/Reducer/getInitialState */
export const getInitialState = () => ({
    searchCriteria: '',
    searchResults: {},
    hasSearchResults: false,
    searchResultsKeys: [],
    selectedKey: '',
    isLoading: false
});

/** @namespace EroswholesaleScandipwa/Store/Klevu/Reducer/KlevuReducer */
export const KlevuReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SET_SEARCH_CRITERIA:
        const { searchCriteria } = action;

        return {
            ...state,
            searchCriteria
        };

    case SET_SEARCH_RESULTS:
        const { searchResults } = action;

        return {
            ...state,
            searchResults
        };

    case SET_HAS_SEARCH_RESULTS:
        const { hasSearchResults } = action;

        return {
            ...state,
            hasSearchResults
        };

    case SET_SEARCH_RESULTS_KEYS:
        const { searchResultsKeys } = action;

        return {
            ...state,
            searchResultsKeys
        };

    case SET_SELECTED_KEY:
        const { selectedKey } = action;

        return {
            ...state,
            selectedKey
        };

    case SET_IS_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };

    default:
        return state;
    }
};

export default KlevuReducer;
