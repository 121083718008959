export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SET_PROMO_BAR_HEIGHT = 'SET_PROMO_BAR_HEIGHT';

/**
 * Records the outer height of the header element so that it can remain a fixed
 * element and we can accurately position other elements
 * @namespace EroswholesaleScandipwa/Store/HeaderHeight/Action/setHeaderHeight
 */
export const setHeaderHeight = (height) => ({
    type: SET_HEADER_HEIGHT,
    headerHeight: height || 0
});

/**
 * Records the outer height of the cms block at the very top of the page so that it can
 * remain a fixed element and we can accurately position other elements
 * @namespace EroswholesaleScandipwa/Store/HeaderHeight/Action/setPromoBarHeight
 */
export const setPromoBarHeight = (height) => ({
    type: SET_PROMO_BAR_HEIGHT,
    promoBarHeight: height || 0
});
