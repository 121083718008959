/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../data/customer';
import { GTM_EVENT_KEY_USER_LOGIN, GTM_EVENT_KEY_USER_SIGN_UP } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const fireLoginEvent = debounceCallback(async (method = 'email') => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_USER_LOGIN,
        customerId,
        method
    });
});

export const fireSignUpEvent = debounceCallback(async (method = 'email') => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_USER_SIGN_UP,
        customerId,
        method
    });
});
