import _ from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CustomAssortmentOptionsType } from 'Type/CustomAssortmentProduct.type';
import { ProductType } from 'Type/ProductList.type';
import { getFinalTotalPrice } from 'Util/BulkProduct';

import CustomAssortmentComponent from './CustomAssortmentMenu.component';

export const BurgerMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/BurgerMenu/BurgerMenu.dispatcher'
    );

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isOpen: state.BurgerMenuReducer.isCustomAssortmentMenuOpen,
    selections: state.CustomAssortmentMenuReducer.selections,
    caseQuantity: state.CustomAssortmentMenuReducer.caseQuantity,
    incrementValue: state.CustomAssortmentMenuReducer.incrementValue,
    itemsPerCase: state.CustomAssortmentMenuReducer.itemsPerCase,
    unitsOfMeasure: state.CustomAssortmentMenuReducer.unitsOfMeasure,
    options: state.CustomAssortmentMenuReducer.options,
    product: state.CustomAssortmentMenuReducer.product
});

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    closeMenu: () => BurgerMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateCustomAssortmentMenuState(false, dispatch)
    )
});

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenu/Container */
export class CustomAssortmentMenuContainer extends PureComponent {
    static defaultProps = {
        product: null
    };

    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        closeMenu: PropTypes.func.isRequired,
        selections: PropTypes.object.isRequired,
        caseQuantity: PropTypes.number.isRequired,
        incrementValue: PropTypes.number.isRequired,
        itemsPerCase: PropTypes.number.isRequired,
        unitsOfMeasure: PropTypes.string.isRequired,
        options: CustomAssortmentOptionsType.isRequired,
        product: ProductType
    };

    state = {
        showAddToCartErrorMessage: false
    };

    containerFunctions = {
        onClose: this.onClose.bind(this),
        setShowAddToCartErrorMessage: this.setShowAddToCartErrorMessage.bind(this)
    };

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        const { selections } = this.props;
        const { selections: prevSelections } = prevProps;

        if (selections !== prevSelections) {
            this.setState({ showAddToCartErrorMessage: false });
        }
    }

    containerProps() {
        const {
            isOpen,
            closeMenu,
            options,
            incrementValue,
            unitsOfMeasure,
            product,
            caseQuantity
        } = this.props;

        const { showAddToCartErrorMessage } = this.state;

        return {
            isOpen,
            closeMenu,
            options,
            incrementValue,
            unitsOfMeasure,
            product,
            selectedQty: this.getSelectedQty(),
            requiredQty: this.getRequiredQty(),
            remainingQty: this.getRemainingQty(),
            subtotal: this.getSubtotal(),
            caseQuantity,
            showAddToCartErrorMessage
        };
    }

    onClose() {
        const { closeMenu } = this.props;

        closeMenu();
    }

    cancel() {
        const { closeMenu } = this.props;

        closeMenu();
    }

    getSelectedQty() {
        const { selections } = this.props;

        return _.sum(_.values(selections));
    }

    getRequiredQty() {
        const { caseQuantity, itemsPerCase } = this.props;

        return caseQuantity * itemsPerCase;
    }

    // eslint-disable-next-line consistent-return
    getRemainingQty() {
        const { selections } = this.props;
        const totalRequiredQty = this.getRequiredQty();

        if (_.isEmpty(selections)) {
            return totalRequiredQty;
        }

        return totalRequiredQty - this.getSelectedQty();
    }

    getSubtotal() {
        const { product, caseQuantity } = this.props;

        return getFinalTotalPrice(product, caseQuantity);
    }

    setShowAddToCartErrorMessage(bool) {
        this.setState({ showAddToCartErrorMessage: bool });
        setTimeout(() => {
            this.setState({ showAddToCartErrorMessage: false });
            // eslint-disable-next-line no-magic-numbers
        }, 7500);
    }

    render() {
        const { product } = this.props;
        if (!product) {
            return null;
        }

        return (
            <CustomAssortmentComponent
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAssortmentMenuContainer);
