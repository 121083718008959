/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import _ from 'lodash';

import getStore from 'Util/Store';

import { getPageData } from '../data/page';
import { waitForCallback } from './wait';

/** @namespace Scandiweb/Gtm/Util/Breadcrumbs/getBreadcrumbs */
export const getBreadcrumbs = async () => {
    await waitForCallback(() => getStore().getState().BreadcrumbsReducer?.breadcrumbs);

    const { breadcrumbs = [] } = getStore().getState().BreadcrumbsReducer;

    const result = {};
    const filtered = breadcrumbs.filter(({ name }) => name.toLowerCase() !== 'home');
    // filtered.shift();
    filtered.reverse()
        .forEach((item, index) => {
            const key = index === 0 ? 'item_category' : `item_category${index + 1}`;
            result[key] = item.name;
        });

    return result;
};

/** @namespace Scandiweb/Gtm/Util/Breadcrumbs/getCategoriesData */
export const getCategoriesData = async () => {
    const { pageType } = await getPageData();

    let breadcrumbs = {};
    if (_.includes(['product', 'category', 'search'], pageType)) {
        breadcrumbs = await getBreadcrumbs();

        const lastKey = _.findLastKey(breadcrumbs);
        if (pageType === 'product' && lastKey) {
            // eslint-disable-next-line fp/no-delete
            delete breadcrumbs[lastKey];
        }
    }

    return breadcrumbs;
};
