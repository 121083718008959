import PropTypes from 'prop-types';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import { AddToCart as SourceAddToCart } from 'SourceComponent/AddToCart/AddToCart.component';
import { DeviceType } from 'Type/Device.type';
import { ProductType } from 'Type/ProductList.type';

import './AddToCart.override.style';

/** @namespace EroswholesaleScandipwa/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCart {
    static propTypes = {
        ...SourceAddToCart.propTypes,
        device: DeviceType.isRequired,
        product: ProductType,
        quantity: PropTypes.number,
        isInStock: PropTypes.bool.isRequired,
        isUseShortLabel: PropTypes.bool.isRequired,
        isUseStaticLabel: PropTypes.bool.isRequired
    };

    renderAddingActionLabel() {
        return __('Adding...');
    }

    renderMainLabel() {
        const {
            quantity,
            isUseShortLabel,
            isUseStaticLabel,
            product: { type_id }
        } = this.props;

        if (type_id === PRODUCT_TYPE.custom_assortment || isUseStaticLabel) {
            return __('Add to Cart');
        }

        const casesLabel = quantity === 1 ? __('case') : __('cases');

        // eslint-disable-next-line fp/no-let
        let label = `${ __('Add') } ${ quantity } ${ casesLabel }`;
        if (!isUseShortLabel) {
            label += ` ${ __('To Cart') }`;
        }

        return label;
    }

    renderLabel() {
        const { isAdding } = this.props;
        const label = isAdding ? this.renderAddingActionLabel() : this.renderMainLabel();

        return (<span>{ label }</span>);
    }

    render() {
        const {
            mix,
            addProductToCart,
            layout,
            isDisabled,
            isAdding,
            product: { is_bulk_product },
            quantity,
            isInStock
        } = this.props;

        if (!isInStock) {
            return null;
        }

        if (!is_bulk_product || quantity === undefined) {
            return super.render();
        }

        return (
            <button
                onClick={ addProductToCart }
                block="Button AddToCart"
                mix={ mix }
                mods={ { layout } }
                disabled={ isDisabled || isAdding }
            >
                { this.renderCartIcon() }
                { this.renderLabel() }
            </button>
        );
    }
}

export default AddToCartComponent;
