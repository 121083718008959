import {
 SET_ADDRESS, SET_RATES, SET_SELECTED_SHIPPING_METHOD, SET_USER_HAS_CHANGED
} from './ShippingEstimator.action';

/** @namespace EroswholesaleScandipwa/Store/ShippingEstimator/Reducer/getInitialState */
export const getInitialState = () => ({
    rates: [],
    selectedShippingMethod: {},
    address: {
        id: null,
        countryId: 'US',
        city: '',
        region: '',
        regionCode: '',
        regionId: 1,
        postcode: ''
    },
    userHasChanged: false
});

/** @namespace EroswholesaleScandipwa/Store/ShippingEstimator/Reducer/ShippingEstimatorReducer */
export const ShippingEstimatorReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case SET_ADDRESS:
            const { address } = action;

            return {
                ...state,
                address
            };

        case SET_RATES:
            const { rates } = action;

            return {
                ...state,
                rates
            };

        case SET_SELECTED_SHIPPING_METHOD:
            const { selectedShippingMethod } = action;

            return {
                ...state,
                selectedShippingMethod
            };

        case SET_USER_HAS_CHANGED:
            const { userHasChanged } = action;

            return {
                ...state,
                userHasChanged
            };

        default:
            return state;
    }
};

export default ShippingEstimatorReducer;
