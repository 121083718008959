import { App as SourceAppComponent } from 'SourceComponent/App/App.component';

/** @namespace EroswholesaleScandipwa/Component/App/Component */
export class AppComponent extends SourceAppComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        // PWA-285
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
            // eslint-disable-next-line no-magic-numbers
        }, 500);
    }

    renderSharedTransition() {
        return null;
    }
}

export default AppComponent;
