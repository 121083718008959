import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field, Fragment } from 'Util/Query';

/** @namespace EroswholesaleScandipwa/Query/ProductList/Query */
export class ProductListQuery extends SourceProductListQuery {
    _getProductInterfaceFields(isVariant, isForLinkedProducts = false, isForWishlist = false) {
        const fields = super._getProductInterfaceFields(isVariant, isForLinkedProducts, isForWishlist);
        fields.push('is_special_price_active');
        fields.push(new Field('is_special_price_active').setAlias('is_sale_product'));
        fields.push('is_bulk_product');
        fields.push('is_new_product');
        fields.push('is_bestseller_product');
        fields.push('has_free_shipping');

        /**
         * We add this field here so that magento loads the attribute in the collection. However
         * we should use items_per_case from bulk_product_case_info so that we always pull it
         * from the same source
         */
        fields.push(new Field('eros_items_per_case').setAlias('items_per_case'));

        if (!isVariant && !isForLinkedProducts) {
            fields.push(this._getCustomAssortmentProductFragment());
        }

        return fields;
    }

    _getCustomAssortmentProductFragment() {
        return new Fragment('CustomAssortmentProduct')
            .addFieldList(this._getCustomAssortmentProductFragmentFields());
    }

    _getCustomAssortmentProductFragmentFields() {
        return [
            new Field('eros_assortment_increment').setAlias('assortment_increment'),
            new Field('items').addFieldList([
                'uid',
                'sku',
                new Field('options').addFieldList([
                    'uid',
                    'position',
                    new Field('product').addFieldList([
                        'sku',
                        'stock_status',
                        new Field('eros_assortment_label').setAlias('assortment_label'),
                        new Field('stock_item').addFieldList([
                            'qty'
                        ])
                    ])
                ])
            ])
        ];
    }

    _getCustomAssortmentProductItemsFields() {
        return [
            'uid',
            'sku',
            'position',
            'eros_assortment_label'
        ];
    }

    getSalableCaseQtyField() {
        return new Field('salable_case_qty');
    }

    getBulkProductPriceTiersField() {
        return new Field('bulk_product_price_tiers')
            .addFieldList([
                'minimum_case_qty',
                'minimum_item_qty',
                'price_per_case',
                'price_per_case_formatted',
                'price_per_item',
                'price_per_item_formatted',
                'special_price_per_case',
                'special_price_per_case_formatted',
                'special_price_per_item',
                'special_price_per_item_formatted',
                'final_price_per_case',
                'final_price_per_case_formatted',
                'final_price_per_item',
                'final_price_per_item_formatted'
            ]);
    }

    getBulkProductCaseInfoField() {
        return new Field('bulk_product_case_info')
            .addFieldList([
                'items_per_case',
                'unit_of_measure',
                'units_of_measure',
                new Field('products')
                    .addFieldList([
                        'entity_id',
                        'sku',
                        'name',
                        'quantity'
                    ])
            ]);
    }

    getVendorField() {
        return new Field('vendor').addField('shipping_time');
    }
}

export default new ProductListQuery();
