import ProductListQuery from 'Query/ProductList.query';
import { CartQuery as SourceCartQuery } from 'SourceQuery/Cart.query';
import { Field } from 'Util/Query';

/** @namespace EroswholesaleScandipwa/Query/Cart/Query */
export class CartQuery extends SourceCartQuery {
    getAddProductToCartMutation(cartId, cartItems) {
        return super.getAddProductToCartMutation(cartId, cartItems)
            .addField(this._getCartField());
    }

    _getCartField() {
        return new Field('cart')
            .addFieldList(['last_added_or_updated_item_id']);
    }

    _getCartTotalsFields() {
        const fields = super._getCartTotalsFields();
        fields.push(this._getShippingAddressFields());
        fields.push('customer_note');

        return fields;
    }

    /**
     * used for prepopulating shipping estimator
     * @returns {Field}
     * @private
     */
    _getShippingAddressFields() {
        return new Field('shipping_address')
            .addFieldList([
                'id',
                'country_id',
                'region',
                'region_id',
                'city',
                'street',
                'postcode'
            ]);
    }

    _getCartItemFields() {
        return [
            ...super._getCartItemFields(),
            this._getBulkProductCaseInfoField()
        ];
    }

    _getBulkProductCaseInfoField() {
        const fields = [
            'case_qty',
            'qty_per_case',
            'total_qty',
            'price_per_case',
            'price_per_case_formatted',
            'price_per_item',
            'price_per_item_formatted',
            'special_price_per_case',
            'special_price_per_case_formatted',
            'special_price_per_item',
            'special_price_per_item_formatted',
            'final_price_per_case',
            'final_price_per_case_formatted',
            'final_price_per_item',
            'final_price_per_item_formatted',
            'label'
        ];

        return new Field('bulk_product_case_info')
            .addFieldList([
                ...fields,
                new Field('children')
                    .addFieldList(fields)
            ]);
    }

    _getProductField() {
        return super._getProductField()
            .addField('is_bulk_product')
            .addField(ProductListQuery.getBulkProductCaseInfoField());
    }

    getSetShippingAddressOnCartFromEstimatorMutation(cartId, address) {
        const input = { cart_id: cartId, shipping_addresses: [address] };

        return new Field('setShippingAddressesOnCart')
            .addArgument('input', 'SetShippingAddressesOnCartInput', input)
            .addField(this._getCartUpdateField());
    }

    getSetShippingMethodOnCartFromEstimatorMutation(cartId, method) {
        const input = { cart_id: cartId, shipping_methods: [method] };

        return new Field('setShippingMethodsOnCart')
            .addArgument('input', 'SetShippingMethodsOnCartInput', input)
            .addField(this._getCartUpdateField());
    }

    getUnsetShippingSelectionFromCartMutation(cartId) {
        return new Field('unsetShippingSelectionFromCart')
            .addArgument('cart_id', 'String', cartId);
    }
}

export default new CartQuery();
