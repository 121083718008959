import { stripNonNumericChars } from 'Util/Common/String';

export const SET_MIN_VALUE = 'SET_MIN_VALUE';
export const SET_MAX_VALUE = 'SET_MAX_VALUE';

/** @namespace EroswholesaleScandipwa/Store/PriceRange/Action/setMinValue */
export const setMinValue = (value) => ({
    type: SET_MIN_VALUE,
    minValue: stripNonNumericChars(value)
});

/** @namespace EroswholesaleScandipwa/Store/PriceRange/Action/setMaxValue */
export const setMaxValue = (value) => ({
    type: SET_MAX_VALUE,
    maxValue: stripNonNumericChars(value)
});
