/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../data/customer';
import { getViewItemData } from '../data/product';
import { GTM_EVENT_KEY_VIEW_ITEM } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

export const fireViewItemEvent = debounceCallback(async (product, categories) => {
    const { customerId } = await getCustomerData();

    pushToDataLayer({
        event: GTM_EVENT_KEY_VIEW_ITEM,
        customerId,
        ...await getViewItemData(product, categories)
    });
});
