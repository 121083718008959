import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    UrlRewritesContainer as SourceUrlRewritesContainer
} from 'SourceRoute/UrlRewrites/UrlRewrites.container';
import { getQueryParam } from 'Util/Url';

export const UrlRewritesDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/UrlRewrites/UrlRewrites.dispatcher'
    );

export {
    mapStateToProps
};

/** @namespace EroswholesaleScandipwa/Route/UrlRewrites/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    requestUrlRewrite: (options) => {
        UrlRewritesDispatcher.then(
            ({ default: dispatcher }) => dispatcher.handleData(dispatch, options)
        );
    }
});

/** @namespace EroswholesaleScandipwa/Route/UrlRewrites/Container */
export class UrlRewritesContainer extends SourceUrlRewritesContainer {
    requestUrlRewrite() {
        const { requestUrlRewrite } = this.props;
        const pageParam = getQueryParam('page', location);

        return requestUrlRewrite({
            urlParam: location.pathname,
            currentPage: !pageParam ? 1 : pageParam
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UrlRewritesContainer);
