import { ContentWrapper as SourceContentWrapper } from 'SourceComponent/ContentWrapper/ContentWrapper.component';
import { mixType } from 'Type/Mix.type';

import './ContentWrapper.override.style';

/** @namespace EroswholesaleScandipwa/Component/ContentWrapper/Component */
export class ContentWrapperComponent extends SourceContentWrapper {
    static propTypes = {
        wrapperMix: mixType
    };
}

export default ContentWrapperComponent;
