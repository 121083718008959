/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { roundFloat } from 'Util/Common/Number';

/** @namespace Scandiweb/Gtm/Data/Purchase/getOrderData */
export const getOrderData = (order) => {
    const {
        increment_id: transaction_id,
        coupon_code: coupon,
        total: {
            subtotal: { value: subtotal, currency },
            total_tax: { value: tax },
            shipping_handling: { total_amount: { value: shipping } }
        }
    } = order;

    return {
        transaction_id,
        value: subtotal,
        tax,
        shipping,
        coupon,
        currency
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getOrderItemData */
export const getOrderItemData = (item) => {
    const {
        product_sku: item_id,
        product_name: item_name,
        bulk_product_case_info,
        bulk_product_case_info: {
            price_per_item,
            final_price_per_item: price,
            total_qty: quantity
        }
    } = item;

    const discount = roundFloat(price_per_item - price);

    return {
        item_id,
        item_name,
        price,
        quantity,
        discount,
        bulk_product_case_info
    };
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getOrderItemsData */
export const getOrderItemsData = (order) => {
    const { items = [] } = order;

    return items.map(getOrderItemData);
};

/** @namespace Scandiweb/Gtm/Data/Purchase/getPurchaseEventData */
export const getPurchaseEventData = async (order) => ({
    ecommerce: {
        ...getOrderData(order),
        items: getOrderItemsData(order)
    }
});
