import _ from 'lodash';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

import {
    ACCOUNT_FORGOT_PASSWORD,
    BODY_CLASS_PAGE_TYPE_PREFIX,
    BODY_CLASS_URL_KEY_PREFIX,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CREATE_ACCOUNT,
    LOGIN,
    SEARCH,
    SWITCH_ITEMS_TYPE
} from 'Component/Router/Router.config';
import {
    CreateAccountPage,
    ForgotPasswordPage,
    LoginAccountPage,
    PasswordChangePage,
Router as SourceRouter, withStoreRegex
 } from 'SourceComponent/Router/Router.component';
import { convertStringToCamelCase } from 'Util/Common/String';
import { addCssClass, removeCssClassnamesThatStartWith } from 'Util/Html';

export * from 'SourceComponent/Router/Router.component';

/** @namespace EroswholesaleScandipwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    static propTypes = {
        ...SourceRouter.propTypes,
        cmsHomePage: PropTypes.string.isRequired
    };

    /**
     * Here we wrap the render function of Route component, which renders each page. We do this
     * so that we can add page-specific classes to the body. Inspired by the fact that we have some
     * pages with gray background and others without. However, Scandi does not add page-specific classes
     * like native Magento frontend does.
     *
     * @type {*}
     */
    [SWITCH_ITEMS_TYPE] = this[SWITCH_ITEMS_TYPE].map((value) => {
        const { name, component: { props, props: { render: origRender } } } = value;
        const wrappedRender = (props) => {
            const { location: { state, pathname } } = props;

            removeCssClassnamesThatStartWith(document.body, BODY_CLASS_URL_KEY_PREFIX);
            removeCssClassnamesThatStartWith(document.body, BODY_CLASS_PAGE_TYPE_PREFIX);

            if (pathname && pathname !== '/') {
                addCssClass(document.body, BODY_CLASS_URL_KEY_PREFIX + convertStringToCamelCase(pathname));
            }

            if (pathname === '/') {
                const { cmsHomePage } = this.props;
                addCssClass(document.body, BODY_CLASS_URL_KEY_PREFIX + convertStringToCamelCase(cmsHomePage));
            }

            if (name === CART) {
                addCssClass(document.body, `${ BODY_CLASS_PAGE_TYPE_PREFIX }Cart`);
            } else if (name === CHECKOUT) {
                addCssClass(document.body, `${ BODY_CLASS_PAGE_TYPE_PREFIX }Checkout`);
            } else if (name === SEARCH || (_.isObject(state) && _.isNumber(state.category))) {
                addCssClass(document.body, `${ BODY_CLASS_PAGE_TYPE_PREFIX }ProductListingPage`);

                if (name === SEARCH) {
                    window.actionName.type = SEARCH;
                }

                if (_.isObject(state) && _.isNumber(state.category)) {
                    window.actionName.type = 'CATEGORY';
                }
            }

            return origRender(props);
        };

        // eslint-disable-next-line react/jsx-no-bind
        value.component = (<Route { ...props } render={ wrappedRender } />);

        switch (name) {
            case CHANGE_PASSWORD:
                // eslint-disable-next-line react/jsx-no-bind
                value.component = <Route path={ withStoreRegex('/customer/account/createPassword') } render={ (props) => <PasswordChangePage { ...props } /> } />;
                break;
            case CREATE_ACCOUNT:
                // eslint-disable-next-line react/jsx-no-bind
                value.component = <Route path={ withStoreRegex('/customer/account/create') } render={ (props) => <CreateAccountPage { ...props } /> } />;
                break;
            case LOGIN:
                // eslint-disable-next-line react/jsx-no-bind
                value.component = <Route path={ withStoreRegex('/customer/account/login') } render={ (props) => <LoginAccountPage { ...props } /> } />;
                break;
            case ACCOUNT_FORGOT_PASSWORD:
                // eslint-disable-next-line react/jsx-no-bind
                value.component = <Route path={ withStoreRegex('/customer/account/forgotpassword') } render={ (props) => <ForgotPasswordPage { ...props } /> } />;
                break;
            default:
        }

        return value;
    });

    renderSectionOfType(type) {
        return this.renderComponentsOfType(type);
    }

    /** todo determine if need to do this */
    /* renderFallbackPage() {
        return '';
    } */
}

export default RouterComponent;
