/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { fireViewItemEvent } from '../../event/product';
import { getCategoriesData } from '../../util/breadcrumbs';

const addFireViewItemEvent = async (args, callback, instance) => {
    const result = callback(...args);

    try {
        const { props: { product } } = instance;
        const breadcrumbs = await getCategoriesData();

        fireViewItemEvent(product, breadcrumbs);
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }

    return result;
};

export default {
    'Route/ProductPage/Container': {
        'member-function': {
            updateMeta: addFireViewItemEvent
        }
    }
};
