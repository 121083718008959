import { SET_LAST_ADDED_OR_UPDATED_ITEM_ID, SET_LAST_ADDED_OR_UPDATED_PRODUCT_ID } from './CartItem.action';

/** @namespace EroswholesaleScandipwa/Store/CartItem/Reducer/getInitialState */
export const getInitialState = () => ({
    lastAddedOrUpdatedItemId: null,
    lastAddedOrUpdatedProductId: null
});

/** @namespace EroswholesaleScandipwa/Store/CartItem/Reducer/CartItemReducer */
export const CartItemReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case SET_LAST_ADDED_OR_UPDATED_ITEM_ID:
            const { lastAddedOrUpdatedItemId } = action;

            return {
                ...state,
                lastAddedOrUpdatedItemId
            };

        case SET_LAST_ADDED_OR_UPDATED_PRODUCT_ID:
            const { lastAddedOrUpdatedProductId } = action;

            return {
                ...state,
                lastAddedOrUpdatedProductId
            };
        default:
            return state;
    }
};

export default CartItemReducer;
