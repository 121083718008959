import CartQuery from 'Query/Cart.query';
import { getGuestQuoteId } from 'Util/Cart/Token';
import history from 'Util/History';
import { fetchMutation } from 'Util/Request/Mutation';

export * from 'SourceUtil/Cart/Cart';

/** @namespace EroswholesaleScandipwa/Util/Cart/isCart */
export const isCart = () => {
    const {
        location: {
            pathname
        }
    } = history;

    return pathname.includes('/cart');
};

/** @namespace EroswholesaleScandipwa/Util/Cart/unsetShippingMethodSelection */
export const unsetShippingMethodSelection = () => {
    const mutation = CartQuery.getUnsetShippingSelectionFromCartMutation(getGuestQuoteId());
    fetchMutation(mutation);
};
