import {
    CheckoutReducer as sourceCheckoutReducer,
    getInitialState as sourceGetInitialState
} from 'SourceStore/Checkout/Checkout.reducer';

import { CHECKOUT_TYPE_SELECTED } from './Checkout.action';

export * from 'SourceStore/Checkout/Checkout.reducer';

/** @namespace EroswholesaleScandipwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    ...sourceGetInitialState(),
    checkoutTypeSelected: false
});

/** @namespace EroswholesaleScandipwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
        case CHECKOUT_TYPE_SELECTED:
            const { checkoutTypeSelected } = action;

            return {
                ...state,
                checkoutTypeSelected
            };
        default:
            return sourceCheckoutReducer(state, action);
    }
};

export default CheckoutReducer;
