import _ from 'lodash';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { CustomAssortmentOptionType } from 'Type/CustomAssortmentProduct.type';
import { roundToNearestIncrementValue } from 'Util/CustomAssortment/Calculator';

import CustomAssortmentMenuOptionComponent from './CustomAssortmentMenuOption.component';

export const CustomAssortmentMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CustomAssortmentMenu/CustomAssortmentMenu.dispatcher'
    );

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOption/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    selections: state.CustomAssortmentMenuReducer.selections
});
/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOption/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateSelections: (selections) => CustomAssortmentMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateSelections(selections, dispatch)
    )
});

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenuOption/Container */
export class CustomAssortmentMenuOptionContainer extends PureComponent {
    static propTypes = {
        incrementValue: PropTypes.number.isRequired,
        option: CustomAssortmentOptionType.isRequired,
        selections: PropTypes.object.isRequired,
        updateSelections: PropTypes.func.isRequired,
        remainingQty: PropTypes.number.isRequired
    };

    containerFunctions = {
        handleInputChange: this.handleInputChange.bind(this),
        handlePlusIconClick: this.handlePlusIconClick.bind(this),
        handleMinusIconClick: this.handleMinusIconClick.bind(this),
        handleBlur: this.handleBlur.bind(this)
    };

    state = {
        showRoundingMessage: false
    };

    containerProps() {
        const { option, incrementValue } = this.props;
        const { showRoundingMessage } = this.state;

        return {
            option,
            qty: this.getQuantity(),
            maxQuantity: this.getMaxQuantity(),
            isInStock: this.isInStock(),
            incrementValue,
            showRoundingMessage
        };
    }

    getQuantity() {
        const { selections, option: { id } } = this.props;
        if (_.isEmpty(selections)) {
            return 0;
        }

        return _.has(selections, id) ? selections[id] : 0;
    }

    getMaxQuantity() {
        const { option: { max_qty: productMaxQty }, remainingQty } = this.props;

        return _.min([productMaxQty, remainingQty + this.getQuantity()]);
    }

    isInStock() {
        const { incrementValue, option: { max_qty: productMaxQty } } = this.props;

        return productMaxQty >= incrementValue;
    }

    setShowRoundingMessage(bool) {
        this.setState({ showRoundingMessage: bool });
        setTimeout(() => {
            this.setState({ showRoundingMessage: false });
            // eslint-disable-next-line no-magic-numbers
        }, 7500);
    }

    /**
     * Handle changes typed directly into the input field
     * @param e
     */
    handleInputChange(e) {
        const {
            selections,
            updateSelections,
            option: { id }
        } = this.props;

        const value = typeof e === 'string' || typeof e === 'number' || e === null
            ? e
            : e.currentTarget.value;

        updateSelections({
            ...selections,
            [id]: (value * 1)
        });
    }

    handlePlusIconClick(value) {
        const {
            selections,
            updateSelections,
            incrementValue,
            option: { id }
        } = this.props;

        updateSelections({
            ...selections,
            [id]: (value * 1) + incrementValue
        });
    }

    handleMinusIconClick(value) {
        const {
            selections, updateSelections, incrementValue, option: { id }
        } = this.props;

        updateSelections({
            ...selections,
            [id]: (value * 1) - incrementValue
        });
    }

    /**
     * If typed value is not evenly divisible, round it to a divisible number if possible
     */
    handleBlur(_e) {
        const {
            selections,
            updateSelections,
            incrementValue,
            option: { id }
        } = this.props;

        const value = _.isUndefined(selections[id]) ? 0 : selections[id];
        if (value === 0) {
            return;
        }

        const adjustedValue = roundToNearestIncrementValue(value, incrementValue, this.getMaxQuantity());
        if (adjustedValue !== value) {
            this.setShowRoundingMessage(true);
        }

        updateSelections({
            ...selections,
            [id]: adjustedValue
        });
    }

    render() {
        return (
            <CustomAssortmentMenuOptionComponent
                { ...this.containerProps() }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomAssortmentMenuOptionContainer);
