/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import _ from 'lodash';

import getStore from 'Util/Store';

import { fireSearchEvent } from '../../event/search';
import { waitForCallback } from '../../util/wait';

export const addFireSearchEvent = async (eventType, clickedItem = null) => {
    try {
        await waitForCallback(() => getStore().getState().KlevuReducer?.searchCriteria);
        const { searchCriteria, searchResults, hasSearchResults } = getStore().getState().KlevuReducer;

        fireSearchEvent({
            searchCriteria,
            searchResults,
            hasSearchResults,
            eventType,
            clickedItem
        });
    } catch (e) {
        console.error('An error occurred while trying to transmit GTM data.', e);
    }
};

export const addFireSearchEnterEvent = async (args, callback, _instance) => {
    const result = callback(...args);
    await addFireSearchEvent('term');

    return result;
};

export const addFireSearchProductViewAllClickEvent = async (args, callback, _instance) => {
    const result = callback(...args);
    await addFireSearchEvent('productViewAllClick');

    return result;
};

export const addFireSearchProductItemClickEvent = async (args, callback, _instance) => {
    const result = callback(...args);
    await addFireSearchEvent('productItemClick', _.first(args));

    return result;
};

export const addFireSearchCategoryItemClickEvent = async (args, callback, _instance) => {
    const result = callback(...args);
    await addFireSearchEvent('categoryItemClick', _.first(args));

    return result;
};

export const addFireSearchSuggestionItemClickEvent = async (args, callback, _instance) => {
    const result = callback(...args);
    await addFireSearchEvent('suggestionItemClick', _.first(args));

    return result;
};

export default {
    'EroswholesaleScandipwa/Component/SearchField/Container': {
        'member-function': {
            executeTermSearch: addFireSearchEnterEvent
        }
    },
    'EroswholesaleScandipwa/Util/Klevu/Index/viewAllClickHandler': {
        function: addFireSearchProductViewAllClickEvent
    },
    'EroswholesaleScandipwa/Util/Klevu/Index/productClickHandler': {
        function: addFireSearchProductItemClickEvent
    },
    'EroswholesaleScandipwa/Util/Klevu/Index/categoryClickHandler': {
        function: addFireSearchCategoryItemClickEvent
    },
    'EroswholesaleScandipwa/Util/Klevu/Index/suggestionClickHandler': {
        function: addFireSearchSuggestionItemClickEvent
    }
};
