import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PRODUCT_TYPE } from 'Component/Product/Product.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/AddToCart/AddToCart.container';
import { DeviceType } from 'Type/Device.type';
import { addQuantityToSelection } from 'Util/CustomAssortment/Selection';
import { ADD_TO_CART } from 'Util/Product';
import { getProductInStock } from 'Util/Product/Extract';
import { magentoProductTransform } from 'Util/Product/Transform';

export const BurgerMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/BurgerMenu/BurgerMenu.dispatcher'
    );

export const CartItemDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CartItem/CartItem.dispatcher'
    );

export const CustomAssortmentMenuDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/CustomAssortmentMenu/CustomAssortmentMenu.dispatcher'
    );

/** @namespace EroswholesaleScandipwa/Component/AddToCart/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    customAssortmentSelections: state.CustomAssortmentMenuReducer.selections
});

/** @namespace EroswholesaleScandipwa/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    openMiniCart: () => BurgerMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateMiniCartState(true, dispatch)
    ),
    closeCustomAssortmentMenu: () => BurgerMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateCustomAssortmentMenuState(false, dispatch)
    ),
    resetCustomAssortmentMenuSelections: () => CustomAssortmentMenuDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateSelections({}, dispatch)
    ),
    setLastAddedOrUpdatedProductId: (id) => CartItemDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateLastAddedOrUpdatedProductId(id, dispatch)
    )
});

/** @namespace EroswholesaleScandipwa/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    static defaultProps = {
        isUseShortLabel: false,
        isUseStaticLabel: false,
        customAssortmentSelections: {}
    };

    static propTypes = {
        ...SourceAddToCartContainer.propTypes,
        device: DeviceType.isRequired,
        isUseShortLabel: PropTypes.bool,
        isUseStaticLabel: PropTypes.bool,
        customAssortmentSelections: PropTypes.object,
        closeCustomAssortmentMenu: PropTypes.func.isRequired,
        resetCustomAssortmentMenuSelections: PropTypes.func.isRequired,
        openMiniCart: PropTypes.func.isRequired,
        setLastAddedOrUpdatedProductId: PropTypes.func.isRequired
    };

    typeValidationMap = {
        ...this.typeValidationMap,
        [PRODUCT_TYPE.custom_assortment]: this.validateBundle.bind(this),
        [PRODUCT_TYPE.custom_combo]: this.validateBundle.bind(this)
    };

    executeAfterAddToCartActions() {
        const {
            product: { id },
            setLastAddedOrUpdatedProductId,
            openMiniCart,
            closeCustomAssortmentMenu
        } = this.props;

        setLastAddedOrUpdatedProductId(id);
        openMiniCart();
        closeCustomAssortmentMenu();
    }

    async addCustomAssortmentProductToCart(e) {
        const {
            product,
            quantity,
            fallbackAddToCart,
            cartId,
            customAssortmentSelections,
            resetCustomAssortmentMenuSelections
        } = this.props;

        e.preventDefault();
        e.stopPropagation();
        this.setState({ isAdding: true });

        const magentoProduct = magentoProductTransform(
            ADD_TO_CART,
            product,
            quantity,
            [],
            _.map(customAssortmentSelections, (v, k) => {
                if (!v) {
                    return null;
                }

                return addQuantityToSelection(k, v);
            }).filter((v) => v !== null)
        );

        await fallbackAddToCart({
            products: magentoProduct,
            cartId
        }).catch(
            /** @namespace EroswholesaleScandipwa/Component/AddToCart/Container/AddToCartContainer/addCustomAssortmentProductToCart/fallbackAddToCart/catch */
            (e) => {
                this.setState({ isAdding: false });
                throw e;
            }
        );

        this.setState({ isAdding: false });
        this.executeAfterAddToCartActions();
        resetCustomAssortmentMenuSelections();
    }

    addProductToCart(e) {
        const { product } = this.props;
        if (!product || Object.keys(product).length === 0) {
            return;
        }

        const { type_id } = product;
        if (type_id !== PRODUCT_TYPE.custom_assortment) {
            super.addProductToCart(e)
                .then(
                    this.executeAfterAddToCartActions.bind(this),
                    /** @namespace EroswholesaleScandipwa/Component/AddToCart/Container/AddToCartContainer/addProductToCart/addProductToCart/then/catch */
                    () => {
                        this.setState({ isAdding: false });
                    }
                );

            return;
        }

        this.addCustomAssortmentProductToCart(e);
    }

    containerProps() {
        const {
            device,
            product,
            quantity,
            isUseShortLabel,
            isUseStaticLabel
        } = this.props;

        return {
            ...super.containerProps(),
            device,
            product,
            quantity,
            isUseShortLabel,
            isUseStaticLabel,
            isIconEnabled: true,
            isInStock: getProductInStock(product)
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
