import { setQueryParams as sourceSetQueryParams } from 'SourceUtil/Url/Url';

export * from 'SourceUtil/Url/Url';

/** @namespace EroswholesaleScandipwa/Util/Url/removeQueryParams */
export const removeQueryParams = (url) => {
    const urlObj = new URL(url);
    urlObj.search = '';

    return urlObj.toString();
};

/** @namespace EroswholesaleScandipwa/Util/Url/addQueryParam */
export const addQueryParam = (url, key, value) => {
    url = new URL(url);
    const params = new URLSearchParams(url.search);
    params.append(key, value);
    url.search = params.toString();

    return url.toString();
};

/** @namespace EroswholesaleScandipwa/Util/Url/setQueryParams */
export const setQueryParams = async (keyValueObject, location, history, callback) => {
    await sourceSetQueryParams(keyValueObject, location, history);

    if (typeof callback === 'function') {
        callback();
    }
};
