import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { slide as BurgerMenu } from 'react-burger-menu';

import AddToCart from 'Component/AddToCart';
import BurgerMenuCloseButton from 'Component/BurgerMenuCloseButton';
import BurgerMenuTitle from 'Component/BurgerMenuTitle';
import CartIcon from 'Component/CartIcon';
import CustomAssortmentMenuOptions from 'Component/CustomAssortmentMenuOptions';
import { LIST_LAYOUT } from 'Route/CategoryPage/CategoryPage.config';
import { CustomAssortmentOptionsType } from 'Type/CustomAssortmentProduct.type';
import { ProductType } from 'Type/ProductList.type';
import { formatPrice } from 'Util/Price';

import './CustomAssortmentMenu.style';

/** @namespace EroswholesaleScandipwa/Component/CustomAssortmentMenu/Component */
export class CustomAssortmentMenuComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        isOpen: PropTypes.bool.isRequired,
        closeMenu: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        options: CustomAssortmentOptionsType.isRequired,
        unitsOfMeasure: PropTypes.string.isRequired,
        incrementValue: PropTypes.number.isRequired,
        selectedQty: PropTypes.number.isRequired,
        remainingQty: PropTypes.number.isRequired,
        requiredQty: PropTypes.number.isRequired,
        caseQuantity: PropTypes.number.isRequired,
        subtotal: PropTypes.number.isRequired,
        showAddToCartErrorMessage: PropTypes.bool.isRequired,
        setShowAddToCartErrorMessage: PropTypes.func.isRequired
    };

    renderCloseButton() {
        const { closeMenu } = this.props;

        return <BurgerMenuCloseButton onClick={ closeMenu } />;
    }

    renderTitle() {
        return <BurgerMenuTitle title={ __('Choose Your Assortment') } />;
    }

    renderRemainingQty() {
        const { remainingQty } = this.props;
        const displayNumber = remainingQty < 0 ? 0 : remainingQty;

        return (
            <div
                block="CustomAssortmentMenu"
                elem="RemainingQty"
            >
                <span>{ `Select ${displayNumber} more to fill your order` }</span>
            </div>
        );
    }

    renderIncrementQty() {
        const { incrementValue, unitsOfMeasure } = this.props;

        return (
            <div
                block="CustomAssortmentMenu"
                elem="IncrementQty"
            >
                <span>{ `Add ${unitsOfMeasure} in increments of ${incrementValue}` }</span>
            </div>
        );
    }

    renderTotalQty() {
        const { unitsOfMeasure, selectedQty, requiredQty } = this.props;

        return (
            <div
                block="CustomAssortmentMenu"
                elem="TotalQty"
            >
                <span
                    block="CustomAssortmentMenu"
                    elem="TotalQtyLabel"
                >
                    { `Total ${unitsOfMeasure}` }
                </span>
                <span>
                    <span
                        block="CustomAssortmentMenu"
                        elem="TotalQtySelected"
                        className={ `banner ${requiredQty === selectedQty ? 'Complete' : ''}` }
                    >
                        { selectedQty }
                    </span>
                    /
                    <span block="CustomAssortmentMenu" elem="TotalQtyRequired">{ requiredQty }</span>
                </span>
            </div>
        );
    }

    renderSubtotal() {
        const { subtotal } = this.props;

        return (
            <div
                block="CustomAssortmentMenu"
                elem="SubtotalWrapper"
            >
                <span
                    block="CustomAssortmentMenu"
                    elem="SubtotalLabel"
                >
                    { __('Subtotal') }
                </span>
                <span
                    block="CustomAssortmentMenu"
                    elem="Subtotal"
                >
                    { formatPrice(subtotal) }
                </span>
            </div>
        );
    }

    renderCancelButton() {
        const { closeMenu } = this.props;

        return (
            <button
                block="CustomAssortmentMenu"
                elem="CancelButton"
                mix={ { block: 'Button', mods: { isHollow: true } } }
                onClick={ closeMenu }
            >
                { __('Cancel') }
            </button>
        );
    }

    renderAddToCartButton() {
        const {
            product,
            remainingQty,
            caseQuantity,
            showAddToCartErrorMessage,
            setShowAddToCartErrorMessage
        } = this.props;

        if (!product) {
            return null;
        }

        const disabled = remainingQty > 0;

        return (
            <>
                { disabled && (
                    <div
                        block="CustomAssortmentMenu"
                        elem="AddToCartWrapper"
                    >
                        <button
                            className="Button AddToCart disabled"
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={ () => setShowAddToCartErrorMessage(true) }
                        >
                            <CartIcon />
                            <span>{ __('Add to Cart') }</span>
                        </button>
                        { showAddToCartErrorMessage && (
                            <div className="Field-ErrorMessages">
                                <div className="Field-ErrorMessage">{ `${__('Select')} ${remainingQty} ${__('more to fill your order')}` }</div>
                            </div>
                        ) }
                    </div>
                ) }
                { !disabled && (
                    <AddToCart
                        product={ product }
                        isDisabled={ disabled }
                        quantity={ caseQuantity }
                        layout={ LIST_LAYOUT }
                        mix={ {} }
                    />
                ) }
            </>
        );
    }

    renderActions() {
        return (
            <div
                block="CustomAssortmentMenu"
                elem="Actions"
            >
                { this.renderCancelButton() }
                { this.renderAddToCartButton() }
            </div>
        );
    }

    renderAdditional() {
        return (
            <div
                block="CustomAssortmentMenu"
                elem="Additional"
            >
                { this.renderSubtotal() }
                { this.renderActions() }
            </div>
        );
    }

    render() {
        const {
            isOpen,
            onClose,
            incrementValue,
            options,
            remainingQty
        } = this.props;

        return (
            <BurgerMenu
                block="CustomAssortmentMenu"
                customBurgerIcon={ false }
                customCrossIcon={ false }
                isOpen={ isOpen }
                onClose={ onClose }
                styles={ {
                    bmMenu: {
                        padding: '0'
                    },
                    bmMenuWrap: {
                        width: '100vw'
                    }
                } }
                className="CustomAssortmentMenu-Wrapper BurgerMenu"
                right
            >
                <div
                    block="CustomAssortmentMenu"
                    elem="Header"
                    className="bm-header"
                    key="CustomAssortmentMenu-Header"
                >
                    { this.renderCloseButton() }
                    { this.renderTitle() }
                </div>
                <div
                    block="CustomAssortmentMenu"
                    elem="ContentWrapper"
                    className="bm-content"
                    key="CustomAssortmentMenu-ContentWrapper"
                >
                    { this.renderRemainingQty() }
                    { this.renderIncrementQty() }
                    <CustomAssortmentMenuOptions
                        options={ options }
                        incrementValue={ incrementValue }
                        remainingQty={ remainingQty }
                    />
                    { this.renderTotalQty() }
                </div>
                { this.renderAdditional() }
            </BurgerMenu>
        );
    }
}

export default CustomAssortmentMenuComponent;
