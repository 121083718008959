import PropTypes from 'prop-types';

import { FIELD_TYPE } from 'Component/Field/Field.config';
import { FieldContainer as SourceFieldContainer } from 'SourceComponent/Field/Field.container';

/** @namespace EroswholesaleScandipwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    static defaultProps = {
        ...SourceFieldContainer.defaultProps,
        usesFloatingLabel: true,
        value: '',
        handlePlusIconClick: null,
        handleMinusIconClick: null
    };

    static propTypes = {
        ...SourceFieldContainer.propTypes,
        usesFloatingLabel: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]),
        handlePlusIconClick: PropTypes.func,
        handleMinusIconClick: PropTypes.func
    };

    state = {
        ...this.state,
        shouldFloat: false,
        isFieldFocused: false,
        passwordVisible: false
    };

    componentDidMount() {
        const { isFieldFocused } = this.state;
        const { value: fieldValue } = this.fieldRef;
        const { attr: { value: attrValue }, type } = this.props;

        this.setState({ shouldFloat: isFieldFocused || this.containsValue(fieldValue) || this.containsValue(attrValue) || type === FIELD_TYPE.select });
    }

    componentDidUpdate(_prevProps) {
        const { isFieldFocused } = this.state;
        const { value: fieldValue } = this.fieldRef;
        const { type } = this.props;

        this.setState({ shouldFloat: isFieldFocused || this.containsValue(fieldValue) || type === FIELD_TYPE.select });
    }

    onChangeHandler(e) {
        if (typeof e === 'string' || typeof e === 'number' || e === null) {
            this.setState({ shouldFloat: this.containsValue(e) });
        } else {
            this.setState({ shouldFloat: this.containsValue(e.target.value) });
        }
    }

    containsValue(value) {
        return value !== undefined && value !== null && value !== '';
    }

    onFocusHandler(_e) {
        this.setState({ isFieldFocused: true });
    }

    onBlurHandler(_e) {
        this.setState({ isFieldFocused: false });
    }

    getEventsProp() {
        const {
            events,
            events: {
                onChange, onFocus, onBlur
            }
        } = super.containerProps();

        const newEvents = {
            ...events,
            onChange: this.onChangeHandler.bind(this),
            onFocus: this.onFocusHandler.bind(this),
            onBlur: this.onBlurHandler.bind(this)
        };

        if (typeof onChange === 'function') {
            newEvents.onChange = (e) => {
                onChange(e);
                this.onChangeHandler.bind(this)(e);
            };
        }

        if (typeof onFocus === 'function') {
            newEvents.onFocus = (e) => {
                onFocus(e);
                this.onFocusHandler.bind(this)(e);
            };
        }

        if (typeof onBlur === 'function') {
            newEvents.onBlur = (e) => {
                onBlur(e);
                this.onBlurHandler.bind(this)(e);
            };
        }

        return newEvents;
    }

    passwordToggle() {
        const { passwordVisible } = this.state;
        this.setState({
            ...this.state,
            passwordVisible: !passwordVisible
        });
    }

    containerProps() {
        const { shouldFloat, passwordVisible } = this.state;
        const {
            usesFloatingLabel,
            handlePlusIconClick,
            handleMinusIconClick
        } = this.props;

        return {
            ...super.containerProps(),
            events: this.getEventsProp(),
            containsValue: this.containsValue.bind(this),
            usesFloatingLabel,
            shouldFloat,
            handlePlusIconClick,
            handleMinusIconClick,
            passwordVisible,
            handlePasswordToggle: this.passwordToggle.bind(this)
        };
    }
}

export default FieldContainer;
