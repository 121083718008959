export const SET_IDENTIFIER = 'SET_IDENTIFIER';
export const SET_LAST_IDENTIFIER = 'SET_LAST_IDENTIFIER';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_CASE_QUANTITY = 'SET_CASE_QUANTITY';
export const SET_INCREMENT_VALUE = 'SET_INCREMENT_VALUE';
export const SET_ITEMS_PER_CASE = 'SET_ITEMS_PER_CASE';
export const SET_UNIT_OF_MEASURE = 'SET_UNIT_OF_MEASURE';
export const SET_UNITS_OF_MEASURE = 'SET_UNITS_OF_MEASURE';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_SELECTIONS = 'SET_SELECTIONS';
export const SET_SELECTION_CALLBACK = 'SET_SELECTION_CALLBACK';

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setIdentifier */
export const setIdentifier = (identifier) => ({
    type: SET_IDENTIFIER,
    identifier
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setLastIdentifier */
export const setLastIdentifier = (lastIdentifier) => ({
    type: SET_LAST_IDENTIFIER,
    lastIdentifier
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setProduct */
export const setProduct = (product) => ({
    type: SET_PRODUCT,
    product
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setCaseQuantity */
export const setCaseQuantity = (caseQuantity) => ({
    type: SET_CASE_QUANTITY,
    caseQuantity
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setIncrementValue */
export const setIncrementValue = (incrementValue) => ({
    type: SET_INCREMENT_VALUE,
    incrementValue
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setItemsPerCase */
export const setItemsPerCase = (itemsPerCase) => ({
    type: SET_ITEMS_PER_CASE,
    itemsPerCase
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setUnitOfMeasure */
export const setUnitOfMeasure = (unitOfMeasure) => ({
    type: SET_UNIT_OF_MEASURE,
    unitOfMeasure
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setUnitsOfMeasure */
export const setUnitsOfMeasure = (unitsOfMeasure) => ({
    type: SET_UNITS_OF_MEASURE,
    unitsOfMeasure
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setOptions */
export const setOptions = (options) => ({
    type: SET_OPTIONS,
    options
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setSelections */
export const setSelections = (selections) => ({
    type: SET_SELECTIONS,
    selections
});

/** @namespace EroswholesaleScandipwa/Store/CustomAssortmentMenu/Action/setSelectionCallback */
export const setSelectionCallback = (setSelectionCallback) => ({
    type: SET_SELECTION_CALLBACK,
    setSelectionCallback
});
