import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CloseIcon from 'Component/CloseIcon';

import './BurgerMenuCloseButton.style';

/** @namespace EroswholesaleScandipwa/Component/BurgerMenuCloseButton/Component */
export class BurgerMenuCloseButtonComponent extends PureComponent {
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        position: PropTypes.string.isRequired
    };

    render() {
        const { onClick, position } = this.props;

        return (
            <div block="BurgerMenuCloseButton" className={ position }>
                <button
                    block="BurgerMenuCloseButton"
                    elem="Button"
                    onClick={ onClick }
                    className="bm-close"
                    aria-label="Close"
                >
                    <CloseIcon />
                </button>
            </div>
        );
    }
}

export default BurgerMenuCloseButtonComponent;
