import { PRODUCT_TYPE as SOURCE_PRODUCT_TYPE } from 'SourceComponent/Product/Product.config';

export const PRODUCT_TYPE = {
    ...SOURCE_PRODUCT_TYPE,
    custom_combo: 'custom_combo',
    custom_assortment: 'custom_assortment'
};

export const NON_NATIVE_PRODUCT_TYPES = [PRODUCT_TYPE.custom_combo, PRODUCT_TYPE.custom_assortment];

export default PRODUCT_TYPE;
