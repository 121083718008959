import PropTypes from 'prop-types';

export const CustomAssortmentOptionType = PropTypes.shape({
    id: PropTypes.string.isRequired,
    sku: PropTypes.string.isRequired,
    position: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    stock_status: PropTypes.string.isRequired,
    default_value: PropTypes.number.isRequired
});

export const CustomAssortmentOptionsType = PropTypes.arrayOf(CustomAssortmentOptionType);
