import { APP_INSTANCE, AUTH_ALLOWED_METHODS, AUTH_INSTANCE } from 'Store/Firebase/Firebase.action';

/** @namespace EroswholesaleScandipwa/Store/Firebase/Reducer/getInitialState */
export const getInitialState = () => ({
    appInstance: null,
    authInstance: null,
    authAllowedMethods: []
});

/** @namespace EroswholesaleScandipwa/Store/Firebase/Reducer/FirebaseReducer */
export const FirebaseReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
        case APP_INSTANCE:
            const { appInstance } = action;

            return {
                ...state,
                appInstance
            };
        case AUTH_INSTANCE:
            const { authInstance } = action;

            return {
                ...state,
                authInstance
            };
        case AUTH_ALLOWED_METHODS:
            const { authAllowedMethods } = action;

            return {
                ...state,
                authAllowedMethods
            };
        default:
            return state;
    }
};

export default FirebaseReducer;
