import { SET_HEADER_HEIGHT, SET_PROMO_BAR_HEIGHT } from './HeaderHeight.action';

/** @namespace EroswholesaleScandipwa/Store/HeaderHeight/Reducer/getInitialState */
export const getInitialState = () => ({
    headerHeight: 0,
    promoBarHeight: 0
});

/** @namespace EroswholesaleScandipwa/Store/HeaderHeight/Reducer/HeaderHeightReducer */
export const HeaderHeightReducer = (
    state = getInitialState(),
    action
) => {
    switch (action.type) {
    case SET_HEADER_HEIGHT:
        const { headerHeight } = action;

        return {
            ...state,
            headerHeight
        };

    case SET_PROMO_BAR_HEIGHT:
        const { promoBarHeight } = action;

        return {
            ...state,
            promoBarHeight
        };

    default:
        return state;
    }
};

export default HeaderHeightReducer;
