import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './BurgerMenuTitle.style';

/** @namespace EroswholesaleScandipwa/Component/BurgerMenuTitle/Component */
export class BurgerMenuTitleComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object
        ]).isRequired
    };

    render() {
        const { title } = this.props;

        return (
            <div block="BurgerMenuTitle">
                <div block="BurgerMenuTitle" elem="Title">
                    { title }
                </div>
            </div>
        );
    }
}

export default BurgerMenuTitleComponent;
