export const APP_INSTANCE = 'APP_INSTANCE';
export const AUTH_INSTANCE = 'AUTH_INSTANCE';
export const AUTH_ALLOWED_METHODS = 'AUTH_ALLOWED_METHODS';

/** @namespace EroswholesaleScandipwa/Store/Firebase/Action/setAppInstance */
export const setAppInstance = (appInstance) => ({
    type: APP_INSTANCE,
    appInstance
});

/** @namespace EroswholesaleScandipwa/Store/Firebase/Action/setAuthInstance */
export const setAuthInstance = (authInstance) => ({
    type: AUTH_INSTANCE,
    authInstance
});

/** @namespace EroswholesaleScandipwa/Store/Firebase/Action/setAuthAllowedMethods */
export const setAuthAllowedMethods = (authAllowedMethods) => ({
    type: AUTH_ALLOWED_METHODS,
    authAllowedMethods
});
