import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FIELD_TYPE, FLOATING_LABEL_FIELD_TYPES } from 'Component/Field/Field.config';
import { FieldNumberContainer } from 'Component/FieldNumber/FieldNumber.container';
import { Field as SourceField } from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import './Field.override.style';

/** @namespace EroswholesaleScandipwa/Component/Field/Component */
export class FieldComponent extends SourceField {
    static defaultProps = {
        handlePlusIconClick: null,
        handleMinusIconClick: null
    };

    static propTypes = {
        ...SourceField.propTypes,
        usesFloatingLabel: PropTypes.bool.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ]).isRequired,
        handlePlusIconClick: PropTypes.func,
        handleMinusIconClick: PropTypes.func
    };

        // #region INPUT TYPE RENDER
    renderDefaultInput() {
        const {
            type,
            setRef,
            attr,
            events,
            isDisabled,
            passwordVisible
        } = this.props;

        return type === FIELD_TYPE.password ? (
            <input
                ref={ (elem) => setRef(elem) }
                disabled={ isDisabled }
                type={ passwordVisible ? FIELD_TYPE.text : FIELD_TYPE.password }
                { ...attr }
                { ...events }
            />
        ) : super.renderDefaultInput();
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label
        } = this.props;
        const { id = '', checked, value = '' } = newAttr;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };
        // if button value is "none" do not disable
        const isButtonDisabled = (!_.toString(value).match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                    ref={ (elem) => setRef(elem) }
                    disabled={ isButtonDisabled ? isDisabled : false }
                    type={ type }
                    { ...newAttr }
                    { ...inputEvents }
                    // shipping options have checked attr assigned so prioritize its value
                    defaultChecked={ isChecked }
                />
                <div block="input-control" disabled={ isDisabled } />
                { label }
            </label>
        );
    }

    renderNumber() {
        const {
            attr,
            events,
            setRef,
            value,
            isDisabled = false,
            handlePlusIconClick,
            handleMinusIconClick
        } = this.props;

        return (
            <FieldNumberContainer
                value={ value }
                attr={ attr }
                events={ events }
                setRef={ setRef }
                isDisabled={ isDisabled }
                handlePlusIconClick={ handlePlusIconClick }
                handleMinusIconClick={ handleMinusIconClick }
            />
        );
    }

    render() {
        const {
            type,
            validationResponse,
            mix = {},
            usesFloatingLabel,
            shouldFloat,
            isDisabled,
            handlePasswordToggle,
            passwordVisible
        } = this.props;
        const inputRenderer = this.renderMap[type];
        const { mods: { hasError = false } = {} } = mix;

        const isFloatingLabelType = FLOATING_LABEL_FIELD_TYPES.indexOf(type) >= 0;
        if (!usesFloatingLabel || !isFloatingLabelType) {
            return super.render();
        }

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                    block="Field"
                    mods={ {
                        type,
                        isValid: !hasError && validationResponse === true,
                        hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0,
                        usesFloatingLabel,
                        isDisabled
                    } }
                    mix={ mix }
                    className={ `${ shouldFloat ? 'float-label' : '' }` }
                >
                    { type !== FIELD_TYPE.checkbox && type !== FIELD_TYPE.radio && this.renderLabel() }
                    { inputRenderer && inputRenderer() }
                    { type === FIELD_TYPE.password && (
                    passwordVisible ? (
                        <FontAwesomeIcon
                            icon={ faEyeSlash }
                            onClick={ handlePasswordToggle }
                            className="fa-icon"
                            size="2x"
                            onKeyDown={ handlePasswordToggle }
                        />
                    ) : (
                        <FontAwesomeIcon
                            icon={ faEye }
                            onClick={ handlePasswordToggle }
                            className="fa-icon"
                            size="2x"
                            onKeyDown={ handlePasswordToggle }
                        />
                    )
                    ) }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
