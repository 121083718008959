/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/Gtm/Query/Config/Query */
export class ConfigQuery {
    _getConfigFields = () => ([
        'enabled',
        'gtm_id',
        this._getEventField()
    ]);

    _getEventField = () => new Field('events')
        .addFieldList(this._getEventFields());

    _getEventFields = () => ([
        'gtm_general_init',
        'gtm_product_add_to_cart',
        'gtm_product_remove_from_cart',
        'gtm_purchase',
        'gtm_search',
        'gtm_login',
        'gtm_view_item',
        'gtm_view_cart',
        'gtm_begin_checkout',
        'gtm_add_shipping_info',
        'gtm_add_payment_info',
        'gtm_checkout_viewed_shipping_methods'
    ]);

    getGtmField = () => new Field('getGtm')
        .setAlias('gtm')
        .addFieldList(this._getConfigFields());
}

export default new ConfigQuery();
