/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { getCustomerData } from '../data/customer';
import { GTM_EVENT_KEY_SEARCH } from '../util/events';
import { pushToDataLayer } from '../util/push';
import { debounceCallback } from '../util/wait';

/** @namespace Scandiweb/Gtm/Event/General/fireGeneralEvent */
export const fireSearchEvent = debounceCallback(
    async ({
        searchCriteria,
        searchResults,
        hasSearchResults,
        eventType,
        clickedItem = null
    }) => {
        const { customerId } = await getCustomerData();

        pushToDataLayer({
            event: GTM_EVENT_KEY_SEARCH,
            customerId,
            search_term: searchCriteria,
            hasSearchResults,
            searchType: eventType,
            clickedItem,
            klevuSearchResults: searchResults
        });
    }
);
