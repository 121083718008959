/**
 * Google Tag Manager frontend compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import TagManager from 'react-gtm-module';

import getStore from 'Util/Store';

import { checkIsEventEnable } from './checkIsEventEnable';
import { waitForCallback } from './wait';

// eslint-disable-next-line fp/no-let, @scandipwa/scandipwa-guidelines/export-level-one
let isGtmInitialized = false;

// eslint-disable-next-line @scandipwa/scandipwa-guidelines/export-level-one
const beforeInitQue = [];

/** @namespace Scandiweb/Gtm/Util/Push/pushToDataLayer */
export const pushToDataLayer = async (data) => {
    await waitForCallback(() => getStore().getState().ConfigReducer?.gtm?.enabled);
    // TODO: ^^^ if connection is too poor it might change the behavior and delay the initialization until next event

    // vvv Push request must be queued
    beforeInitQue.push(data);
    // ^^^ This will be used for when configReducer is still loading or gtm is not initialized yet

    const { enabled: isEnabled, gtm_id: gtmId } = getStore().getState().ConfigReducer.gtm;

    if (isEnabled === undefined) {
        return;
    }

    const { event } = data;
    if (!checkIsEventEnable(event)) {
        return;
    }

    if (isGtmInitialized === false) {
        TagManager.initialize({ gtmId });
        TagManager.dataLayer({ dataLayer: { ecommerce: null } });
        beforeInitQue.forEach((qData) => TagManager.dataLayer({ dataLayer: qData }));

        isGtmInitialized = true;

        beforeInitQue.splice(0, beforeInitQue.length);

        return;
    }

    beforeInitQue.pop();
    TagManager.dataLayer({
        dataLayer: {
            ecommerce: null,
            method: null,
            search_term: null,
            hasSearchResults: null,
            searchType: null,
            klevuSearchResults: null,
            clickedItem: null
        }
    });
    TagManager.dataLayer({ dataLayer: data });
};
