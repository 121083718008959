import { isMobile as sourceIsMobile } from 'SourceUtil/Mobile/isMobile';

export * from 'SourceUtil/Mobile/isMobile';

export const isMobile = {
    ...sourceIsMobile,
    any: () => window.matchMedia('(max-width: 1130px)').matches && window.matchMedia('screen').matches
};

export default isMobile;
